import { httpClient } from '@/app/shared/services/http-client'

export async function fetchNewToken() {
  try { 
    let response =null;
    await httpClient.get(`/getPowerBIToken`, {headers: {'Content-Type': 'multipart/form-data'}})
      .then((res) => {
        console.log(res.data)
        response=res.data     
      }) 
     return response.accessToken;
  } catch (error) {
    console.error('Failed to fetch new token:', error);
    throw error;
  }
}
