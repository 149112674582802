<template>
  <div>
    <plotly v-if="plotlyData.data?.length > 0" 
        :data="plotlyData.data" :layout="plotlyData.layout" :options="plotlyData.options" :autoResize="plotlyData.autoResize" 
    />  
    <!-- <div v-else>Loading...</div> -->
  </div>    
</template>

<script>
export default {
  props:{
    title: String, 
    rawData: Array, 
    color: null ,
    layout: Object
  },
  data() {
    return {
      plotlyData:{
        data: [],
       //  data: this.rawData,         
        layout: {
          title: {
              text: this.title,
              font: {
              family: 'Arial, sans-serif',
              size: 24,
              weight: 'bold'  
            },
            xref: 'paper',  
            x: 0.5  
          },
          xaxis: {          
            ...(this.layout && this.layout.xaxis), 
            type: 'category' 
          },
          yaxis: {          
            tickformat: '.2s',        
            tickprefix: '$',
          },       
        },
        options: {
          responsive: true,
        },
        autoResize: true,
      },    
    };
  },
  watch:{
    rawData: {
      handler(newVal) {      
        this.plotlyData.data = newVal;
      },
      immediate: true 
    }
  }, 
};
</script>

<style>
.card {
  border-radius: 3px;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.21);
  background-color: transparent;
}

</style>
