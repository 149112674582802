<template>
  <div class="benchmark" :style="{ width: 'auto', height: 'auto' }">
    <va-card
      :style="{ alignItems: 'center', justifyContent: 'center', overflow: 'hidden', minHeight: '600px', backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }">

      <div class="row row-equal" :style="{ alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }">
        <div class="row row-equal" :style="{ alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }">
          <va-card :style="{ display: 'none', marginLeft: '2rem', marginRight: '2rem', marginTop: '2rem', marginBottom: '2rem' }">
            <div class="flex xs12 md6 xl4">
              <p :style="{ width: '250px', fontWeight: '600' }"> Find out more about Timeline Analytics </p>
              <br />
              <va-button @click="showTimeline" class="app-navbar-school__button_select">
                Timeline
              </va-button>
            </div>
          </va-card>
          <va-card :style="{ marginLeft: '2rem', marginRight: '2rem', marginTop: '2rem', marginBottom: '2rem' }">
            <div class="flex xs12 md6 xl4 ">
              <p :style="{ width: '250px', fontWeight: '600' }"> Find out more about Analysis Analytics </p>
              <br />
              <va-button @click="showAnalysis" class="app-navbar-school__button_select">
                Analysis
              </va-button>
            </div>
          </va-card>
          <va-card :style="{ marginLeft: '2rem', marginRight: '2rem', marginTop: '2rem', marginBottom: '2rem' }">
            <div class="flex xs12 md6 xl4">
              <p :style="{ width: '250px', fontWeight: '600' }">View the list of Job details </p>
              <br />
              <va-button :style="{ width: '8rem' }" @click="showJobs" class="app-navbar-school__button_select">
                Job Details
              </va-button>
            </div>
          </va-card>
        </div>
        <div class="row row-equal" :style="{ alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }">
          <va-card :style="{ marginLeft: '2rem', marginRight: '2rem', marginTop: '2rem', marginBottom: '2rem' }">
            <div class="flex xs12 md6 xl4 ">
              <p :style="{ width: '250px', fontWeight: '600' }"> View the job in progress with Rule details </p>
              <br />
              <va-button :style="{ width: '12rem' }" @click="showDirective" class="app-navbar-school__button_select">
                Directive Principles
              </va-button>
            </div>
          </va-card>
          <va-card :style="{ marginLeft: '2rem', marginRight: '2rem', marginTop: '2rem', marginBottom: '2rem' }">
            <div class="flex xs12 md6 xl4 ">
              <p :style="{ width: '250px', fontWeight: '600' }"> Let's begin the process by uploading file </p>
              <br />
              <va-button @click="showUpload" class="app-navbar-school__button_select">
                Upload
              </va-button>
            </div>
          </va-card>
          <va-card :style="{ marginLeft: '2rem', marginRight: '2rem', marginTop: '2rem', marginBottom: '2rem' }">
            <div class="flex xs12 md6 xl4 ">
              <p :style="{ width: '250px', fontWeight: '600' }"> We are here for Support </p>
              <br />
              <va-button @click="showSupport" class="app-navbar-school__button_select">
                Support
              </va-button>
            </div>
          </va-card>
        </div>
      </div>
    </va-card>
  </div>
</template>
<script>

import { mapState, mapActions } from "vuex";
import { loadingService } from '@/app/shared/services/loading-service'
import Timeline from './timeline';
import Analysis from './analysis';
import appInsights from '../../../../appInsights';

export default {
  name: "payrolldashboard",
  components: {
    Timeline,
    Analysis
  },
  data() {
    return {
      loading: false,
      pageStartTime: null
    }
  },
  async mounted() {
    this.pageStartTime = Date.now();
    appInsights.trackPageView({
      name: 'Payrolldashboard Page',
      properties: {
        currentRoute: 'payrolldashboard'
      }
    });
  },
  destroyed() {
    const timeSpent = Date.now() - this.pageStartTime;
    appInsights.trackPageView({
      name: 'Payrolldashboard Page',
      properties: {
        duration: timeSpent,
        currentRoute: 'payrolldashboard',
        entity: this.selectedEntity.entity_name,
        user: (this.user?.firstName || '') + ' ' + (this.user?.lastName || '')
      }
    });
  },
  watch: {
    selectedEntity(newValue, oldValue) {
      this.handleSelectedEntityChange(newValue, oldValue);
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
      selectedEntity: state => state.user.selectedEntity,
    }),
  },

  methods: {
    ...mapActions(
      {
      }
    ),
    showTimeline() {
      this.$router.push({ name: 'payroll/timeline' })
        // eslint-disable-next-line handle-callback-err
        .catch(err => { })
    },
    async handleSelectedEntityChange(newValue, oldValue) {
      await this.load_payroll_filters_data();
    },
    showAnalysis() {
      this.$router.push({ name: 'payroll/analysis' })
        // eslint-disable-next-line handle-callback-err
        .catch(err => { })
    },
    showJobs() {
      this.$router.push({ name: 'joblist' })
        // eslint-disable-next-line handle-callback-err
        .catch(err => { })
    },
    showDirective() {
      this.$router.push({ name: 'payroll' })
        // eslint-disable-next-line handle-callback-err
        .catch(err => { })
    },
    showUpload() {
      this.$router.push({ name: 'payroll' })
        // eslint-disable-next-line handle-callback-err
        .catch(err => { })
    },
    showSupport() {
      this.$router.push({ name: 'payroll' })
        // eslint-disable-next-line handle-callback-err
        .catch(err => { })
    },
  }
}
</script>

<style lang="scss" scoped>
.customdivCharts {
  align-content: center;
  margin-left: 50px;
}
</style>
