import Report from './views/report'
import Analytics from './views/adminanalytics'

const reportRoutes = [
    {
        path: 'report/:codename',
        name: 'report',
        component: Report,
        exact: true,
        props: route => ({
            codename: route.params.codename,
            name: route.params.name 
        }),
        meta: {
            breadcrumb: [
                { name: 'Report' }
          ],
            requiresReportPermission: true,
           
        }
    },
    {
        path: 'report',
        redirect: { name: 'dashboard' },
        name: 'report',
        component: Report,
        meta: {
            breadcrumb: [
                { name: 'Report' }
            ]
        }
    },
    {
        path: 'adminanalytics',
        name: 'adminanalytics',
        component: Analytics,       
    },
]

export default reportRoutes
