import LandingPage from './views/landing-page'

const landingRoutes = [
  {
    path: '/home',
    name: 'home',
    component: LandingPage,
  },
]

export default landingRoutes
