<template>
  <div id="main-container">
    <modals-container @savedata="handleSaveRule" @editdata="handleEditRule"  @deletedata="handleDeleteRule" />
    <div v-if="jobSteps" class="step-progress-payroll">
      <va-card>
        <h2>Job Progress</h2>
        <hr>
        <v-step-progress :steps="mySteps" :current-step="currentStep" active-color="#177f1d" passive-color="#e12d2d"
          :active-thickness="1" :passive-thickness="1" :line-thickness="1" icon-class="fa fa-check">
        </v-step-progress>
      </va-card>
    </div>
    <hr />

    <div class="row row-equal">
      <div class="flex xs12">
        <div class="jobs-details row">
          <va-card class="flex xs6">
            <h1>Job Info</h1>
            <hr />
            <div class="row">
              <div class="flex">
                <ul>                 
                  <li class="label-style">Status:  {{ jobsData?.runtimeStatus }}</li>
                  <li class="label-style">Estimated Time to Finish (min): {{ jobsData?.estimated_time_minutes }} </li>
                  <li class="label-style">Created: {{ jobsData?.createdTime }} </li>
                </ul>
              </div>
            </div>
          </va-card>
          <va-card gradient class="flex xs6">
            <h2>Files Data</h2>
            <hr />
            <div class="row">
              <div class="flex xs6">
                <ul>                  
                  <li class="label-style">File Name: {{  filesData?.file_name }}</li>
                  <li class="label-style">File Extension : {{ filesData?.file_ext }}</li>
                </ul>
              </div>
            </div>
          </va-card>
        </div>
      </div>
    </div>
    <hr />
    <div class="row row-equal">
      <div class="flex xs12">
        <va-card class="flex xs12">
          <div class="row">
            <Header title="Rules" />
            <div class="flex xs12">
              <PayrollTabs :tabNames="tabNames" v-model="selectedDataSetIndex" />
            </div>
          </div>
          <va-card class="va-card-style-internal">
            <div class="row">
              <div class="flex xs12">
                <p>List of rules ready for review</p>
                <hr>
                <!----<div class="flex xs3">
                  <va-select
                    v-model="employmentType"
                    :options="employmentTypeOptions"
                    noClear
                  />
                </div>-->
                <PayrollDataTable :selectedDataSetIndex="selectedDataSetIndex" :items="getItemsForSelectedTab"
                  :fields="fields" @editRule="openEditRuleModal" @deleteRule="openDeleteRuleModal" />
              </div>
            </div>
            <div class="action-buttons">
              <va-button medium @click="openCreateRuleModal(jobsData?.instanceId)">Add a New Rule</va-button>
              <va-button medium @click="handleFinalButtonClick(jobsData?.instanceId)">Final Review & Sign off</va-button>
            </div>      

          </va-card>
        </va-card>
      </div>
    </div>
  </div>

</template>

<script>

import PayrollDataTable from "./components/PayrollDataTable.vue";
import CreateRuleModal from "./components/CreateRuleModal.vue";
import EditRuleModal from "./components/EditRuleModal.vue";
import Header from "./components/Header.vue";
import FileUploader from "./components/FileUploader.vue";
import ActionButton from "./components/ActionButton.vue";
import PayrollTabs from "./components/PayrollTabs.vue";
import ActionButtons from "./components/ActionButtons.vue";
import VModal from 'vue-js-modal/dist/ssr.index';
import { httpClient } from '@/app/shared/services/http-client'
import { loadingService } from '@/app/shared/services/loading-service'
import StepProgress from 'vue-step-progress';
import 'vue-step-progress/dist/main.css';
import formatDateString from '../utilities';
import DeleteRuleModal from "./components/DeleteRuleModal.vue";
import FinalSignoffModal from "./components/FinalSignoffModal.vue";
import appInsights from '../../../appInsights';
import { mapState, mapActions } from "vuex";

export default {
  name: "payroll",
  components: {
    PayrollDataTable,
    Header,
    FileUploader,
    ActionButton,
    PayrollTabs,
    ActionButtons,
    CreateRuleModal,
    EditRuleModal,
    DeleteRuleModal,
    FinalSignoffModal,
    'v-step-progress': StepProgress
  },
  async created() {   
    this.currentStep = this.$route.query.currentStep
    this.jobsData = this.$route.query.jobsData
    this.filesData = this.$route.query.filesData 
    this.instanceId = this.jobsData['instanceId']   
    const url= `/adminJobDetailsInstance?id=${this.jobsData['instanceId']}`
    loadingService.showLoading(false); 
    await httpClient.get(url)
      .then(res => {
        const jsonData = Object.values(res.data)[0];       
        this.jobId = jsonData[0]?.jobId
      
        this.itemsShift = jsonData.filter((rule) => ['Shift'].includes(rule.rule_category)).map((item, index) => ({
          ...item,
          number: index + 1,
          // created_date: formatDate(item.created_date),
          // updated_date: formatDate(item.updated_date)
        }));
        
        this.itemsOvertime = jsonData.filter((rule) => ['Overtime'].includes(rule.rule_category)).map((item, index) => ({
          ...item,
          number: index + 1,
        }));
        this.itemsRDO = jsonData.filter((rule) => ['RDO'].includes(rule.rule_category)).map((item, index) => ({
          ...item,
          number: index + 1,
        }));
        this.itemsAllowance = jsonData.filter((rule) => ['Allowance'].includes(rule.rule_category)).map((item, index) => ({
          ...item,
          number: index + 1,
        }));
        this.itemsLeave = jsonData.filter((rule) => ['Leave'].includes(rule.rule_category)).map((item, index) => ({
          ...item,
          number: index + 1,
        }));
        this.itemsSuper = jsonData.filter((rule) => ['Super'].includes(rule.rule_category)).map((item, index) => ({
          ...item,
          number: index + 1,
        }));
        this.itemsMiscellaneous = jsonData.filter((rule) => !['Shift', 'Overtime', 'RDO', 'Allowance', 'Leave', 'Super'].includes(rule.rule_category)).map((item, index) => ({
            ...item,
            number: index + 1,          
        })) 
      })
      .catch(err => {
        console.log(err);
        throw err; // reject
      })
      .finally(() => {
        loadingService.showLoading(false);
    })   
  }, 
  data() {
    return {
      itemsShift: [],
      itemsOvertime: [],
      itemsRDO: [],
      itemsAllowance: [],
      itemsLeave: [],
      itemsSuper: [],
      itemsMiscellaneous: [],
      currentStep: -1,
      instanceId: null,
      jobId: null,
      mySteps: [
        "Job Created",
        "File uploaded",
        "Document processed",
        "AI Processing started",
        "Rules extracted ",
        "Rules formatted",
        "Complete",
      ],      
      selectedDataSetIndex: 0,
      isChecked: false,      
      isModalVisible: false,
      employmentType: 'Employment Type',
      employmentTypeOptions: ['Permanent', 'Casual/Part Time', 'Contract', 'Full Time'],
      tabNames: ['Shift', 'Overtime', 'RDO', 'Allowance', 'Leave', 'Super', 'Miscellaneous'],
      jobsData: [],
      filesData: [],
      queryData: [],
      jobSteps: [],
      rules: [],
      ruleComments: [],
      pageStartTime: null
    }
  },
  mounted(){
    this.pageStartTime = Date.now(); 
    appInsights.trackPageView({
      name: 'Payroll Page',
      properties: {
        currentRoute: 'payroll'
      }
    }); 
    const estimatedTimeToComplete = 5000;  
    const stepDelay = estimatedTimeToComplete / 4;   
    setTimeout(() => { 
      if (this.jobsData?.runtimeStatus.toLowerCase() === "success") {       
        this.currentStep = 7;       
      } else {      
        this.currentStep = 4;       
        setTimeout(() => {
          this.currentStep = 5;          
          setTimeout(() => {
            this.currentStep = 6;           
            setTimeout(() => {          
                this.currentStep = 7;                
            }, stepDelay); 
          }, stepDelay);
        }, stepDelay); 
      }
    }, 1000); 
  },
  destroyed() {
    const timeSpent = Date.now() - this.pageStartTime; 
    appInsights.trackPageView({
      name: 'Payroll Page',
      properties: {
        duration: timeSpent,
        currentRoute: 'payroll',
        entity: this.selectedEntity.entity_name,
        user: (this.user?.firstName || '') + ' ' + (this.user?.lastName||'')
      }
    });
  },
  computed: {
    ...mapState({
        user: state => state.user,
        selectedEntity: state => state.user.selectedEntity, 
      }), 
    getItemsForSelectedTab() {
        switch (this.selectedDataSetIndex) {
          case 0:          
            return this.itemsShift.map(item => ({
                    ...item,
                    created_date: `${item.created_date.split('T')[0].split('-')[2]}/${item.created_date.split('T')[0].split('-')[1]}/${item.created_date.split('T')[0].split('-')[0]}`,
                    updated_date: `${item.updated_date.split('T')[0].split('-')[2]}/${item.updated_date.split('T')[0].split('-')[1]}/${item.updated_date.split('T')[0].split('-')[0]}`
                }));                
          case 1:
           return this.itemsOvertime.map(item => ({
                    ...item,
                    created_date: `${item.created_date.split('T')[0].split('-')[2]}/${item.created_date.split('T')[0].split('-')[1]}/${item.created_date.split('T')[0].split('-')[0]}`,
                    updated_date: `${item.updated_date.split('T')[0].split('-')[2]}/${item.updated_date.split('T')[0].split('-')[1]}/${item.updated_date.split('T')[0].split('-')[0]}`
                }));
            
          case 2:
            return this.itemsRDO.map(item => ({
                    ...item,
                    created_date: `${item.created_date.split('T')[0].split('-')[2]}/${item.created_date.split('T')[0].split('-')[1]}/${item.created_date.split('T')[0].split('-')[0]}`,
                    updated_date: `${item.updated_date.split('T')[0].split('-')[2]}/${item.updated_date.split('T')[0].split('-')[1]}/${item.updated_date.split('T')[0].split('-')[0]}`
                }));
            
          case 3:
            return this.itemsAllowance.map(item => ({
                    ...item,
                    created_date: `${item.created_date.split('T')[0].split('-')[2]}/${item.created_date.split('T')[0].split('-')[1]}/${item.created_date.split('T')[0].split('-')[0]}`,
                    updated_date: `${item.updated_date.split('T')[0].split('-')[2]}/${item.updated_date.split('T')[0].split('-')[1]}/${item.updated_date.split('T')[0].split('-')[0]}`
                }));
         
            
          case 4:
            return this.itemsLeave.map(item => ({
                    ...item,
                    created_date: `${item.created_date.split('T')[0].split('-')[2]}/${item.created_date.split('T')[0].split('-')[1]}/${item.created_date.split('T')[0].split('-')[0]}`,
                    updated_date: `${item.updated_date.split('T')[0].split('-')[2]}/${item.updated_date.split('T')[0].split('-')[1]}/${item.updated_date.split('T')[0].split('-')[0]}`
                }));        
            
          case 5:
            return this.itemsSuper.map(item => ({
                    ...item,
                    created_date: `${item.created_date.split('T')[0].split('-')[2]}/${item.created_date.split('T')[0].split('-')[1]}/${item.created_date.split('T')[0].split('-')[0]}`,
                    updated_date: `${item.updated_date.split('T')[0].split('-')[2]}/${item.updated_date.split('T')[0].split('-')[1]}/${item.updated_date.split('T')[0].split('-')[0]}`
                }));        
          
            
          case 6:
           return this.itemsMiscellaneous.map(item => ({
                    ...item,
                    created_date: `${item.created_date.split('T')[0].split('-')[2]}/${item.created_date.split('T')[0].split('-')[1]}/${item.created_date.split('T')[0].split('-')[0]}`,
                    updated_date: `${item.updated_date.split('T')[0].split('-')[2]}/${item.updated_date.split('T')[0].split('-')[1]}/${item.updated_date.split('T')[0].split('-')[0]}`
                }));        
          

          default:
            return this.itemsShift.map(item => ({
                ...item,
                created_date: `${item.created_date.split('T')[0].split('-')[2]}/${item.created_date.split('T')[0].split('-')[1]}/${item.created_date.split('T')[0].split('-')[0]}`,
                updated_date: `${item.updated_date.split('T')[0].split('-')[2]}/${item.updated_date.split('T')[0].split('-')[1]}/${item.updated_date.split('T')[0].split('-')[0]}`
            }));        

        }
    },
    fields() {
      return [
      // { key: 'checkbox', label: '', sortable: false, align: 'center', class: 'checkbox-column' },
        {
          name: 'number',
          title: 'Rule#',
          sortField: 'number',
          width: '5%',
        },
        {
          name: 'rule_number',
          title: 'Rule#',
          sortField: 'rule_number',
          width: '5%',
          visible: false
        },
        {
          name: 'rule',
          title: 'Description',
          sortField: 'rule',
          width: '30%',
        },        
        {
          name: 'rule_status',
          title: 'Status',
          sortField: 'rule_status',
          width: '10%',
        },
        {
          name: 'created_date',
          title: 'Created Date',
          sortField: 'created_date',
       //   formatter: (value) => formatDateString(value),
          width: '15%',
        },
        {
          name: 'updated_date',
          title: 'Updated Date',
          sortField: 'updated_date',
       //   formatter: (value) => formatDateString(value), 
          width: '15%',
        },
        {
          name: 'comment',
          title: 'Comment',
          sortField: 'comment',
          width: '35%',
        },
        {
          name: '__slot:actionssel',
          dataClass: 'text-right',
        },
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        }
      ]
    },

  },

  methods: {
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    formatDateString(value)
    {
      return value.split('T')[0] 
    },
    openCreateRuleModal( instanceId) {    
      let category=''
      switch (this.selectedDataSetIndex) {
          case 0:          
          category= 'Shift' 
          break;           
          case 1:
          category= 'Overtime' 
          break;             
          case 2:
          category= 'RDO' 
          break;            
          case 3:
          category= 'Allowance'
          break;  
          case 4:
          category= 'Leave' 
          break;  
          case 5:
          category= 'Super'
          break;  
          case 6:
          category= 'Miscellaneous'
          break;  
          default:
            category ='Shift'  

        }
      this.$modal.show(CreateRuleModal,
        {
          title: 'Create New Rule',
          propsCategory: category,
          propsComment: "",
          propsInstanceId:instanceId
        },
        {
          isAutoHeight: true,
          resizable: false,
          adaptive: true,
          draggable: true,
          root: this.$root
        },
      )
    },
    handleSaveRule(childData) {
      const currentItems = this.getItemsForSelectedTab
      currentItems.push({
        number: currentItems.length + 1,
        Rule: childData.description,
        comment: childData.memo
      })
    },
    handleEditRule(childData) {   
      const currentItems = this.getItemsForSelectedTab
      const foundItem = currentItems.find(item => item.rule_number === childData.number)     
      if (foundItem) {
        foundItem.Rule = childData.description
        foundItem.comment = childData.memo
      }
    },   

    handleDeleteRule(childData) { 
      const dataSources = [
        this.itemsShift,
        this.itemsOvertime,
        this.itemsRDO,
        this.itemsAllowance,
        this.itemsLeave,
        this.itemsSuper,
        this.itemsMiscellaneous
      ];

      const items = dataSources[this.selectedDataSetIndex];      
      if (items) {
        const itemIndex = items.findIndex(item => item.rule_number === childData.number);         
        if (itemIndex !== -1) {
          items.splice(itemIndex, 1); 
        } else {
          console.warn('Item not found for deletion');
        }
      }
    },

    handleFinalButtonClick(instanceId){ 
      this.$modal.show(FinalSignoffModal,
        {
          title: 'Final Review & Sign off Confirmation',        
          propsInstanceId: instanceId
        },
        {
          isAutoHeight: true,
          resizable: false,
          adaptive: true,
          draggable: true,
          root: this.$root
        },
      )
    },
    openEditRuleModal(itemData) {
      this.$modal.show(EditRuleModal,
        {
          title: 'Edit Rule',
          propsCategory: itemData.rule,
          propsComment: itemData.comment,
          propsNumber: itemData.rule_number,
          propsJobId: itemData.job_id
        },
        {
          isAutoHeight: true,
          resizable: false,
          adaptive: true,
          draggable: true,
          root: this.$root
        },
      )
    },  
    openDeleteRuleModal(itemData) { 
      this.$modal.show(DeleteRuleModal,
        {
          title: 'Delete Rule Confirmation',
          propsCategory: itemData.rule,
          propsComment: itemData.comment,
          propsNumber: itemData.rule_number,
          propsJobId: itemData.job_id
        },
        {
          isAutoHeight: true,
          resizable: false,
          adaptive: true,
          draggable: true,
          root: this.$root
        },
      )
    },  
  },
}
</script>

<style lang="scss" scoped>
.custom-file-input-label {
  cursor: pointer;
  display: inline-block;
}

.checkbox-column {
  width: 50px; /* Adjust the width as needed */
  text-align: center;
}

.button-image {
  max-width: 100%;
  height: auto;
}

.label-style {
  font-size: large;
  font-weight: 600;
}

.va-card-style {
  overflow-x: auto;
  background-color: #eeeeee;
  color: white;
}

.va-card-style-internal {
  overflow-x: auto;
  color: black;
}

#main-container {
  background-size: auto;
  color: black;
}

.modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
</style>