
<template>
   <va-card class="container" style="overflow-x: auto; overflow-y: visible;">
    <div class="header">
      <p class="display-5" style="color: #5f6e78;">{{ title }}</p>
    </div>
    <div class="body">
      <p class="message">Please confirm all comments have been added and the rules are ready to proceed to Findex for analysis.</p>
      </div>
      <div class="footer">
        <va-button class="btn" @click="cancel">CANCEL</va-button>
        <va-button class="btn" @click="save">OK</va-button>
      </div>
   
  </va-card>
</template>

<script>
import { httpClient } from '@/app/shared/services/http-client'

export default {
  name: "FinalSignoffModal",
  props: {
    title: String,  
    propsInstanceId: ""
  },
  data() {
    return {      
    };
  },

  methods: {
    cancel() {
      this.$emit("close");
    },
    async save() {       
      const body = {       
        'instance_id': this.propsInstanceId,       
      };     
      await httpClient.put(`/editJob`, body, {headers: {'Content-Type': 'multipart/form-data'}})
      .then((res) => {
        console.log(res.data)
      })             
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
.container {
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
  height: 500px;
  overflow-y: hidden;
}

.header {
  text-align: center;
  margin-bottom: 16px;
  margin-top: 2rem;
}

.display-5 {
  color: #34495e;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
}

.body {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.message {
  margin-bottom: 16px;
  margin-top: 2rem;
  font-size: 1.5rem;
}

.footer {
  display: flex;
  justify-content: center;
  padding: 16px;
  border-top: 1px solid #e0e0e0;
  margin-top: 1.5rem;
  margin-top: 1rem;
}

.btn {
  margin: 0 8px;
}

.alignright {
  float: right;
}

.alignleft {
  float: left;
}
</style>