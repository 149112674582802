import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { config } from "./config";
const appInsights = new ApplicationInsights({   
    config: {
        instrumentationKey: config.api.appInsights,
    }
});

appInsights.loadAppInsights();

export default appInsights;
