import { httpClient } from '@/app/shared/services/http-client'

const underpaymentKeyReasons=[
  {   
    "payment_type": "Total Underpayments",   
  },
  {   
    "payment_type": "Underpaid Employees",   
  }
]

const overpaymentKeyReasons=[
  {   
    "payment_type": "Total Overpayments",    
  },
  {
    "payment_type": "Overpaid Employees",   
  }  
]

function NumberformatinKMB(number) {
  if (Math.abs(number) >= 1e9) {
    return (number / 1e9).toFixed(1) + " B";
  } else if (Math.abs(number) >= 1e6) {
    return (number / 1e6).toFixed(1) + " M";
  } else if (Math.abs(number) >= 1e3) {
    return (number / 1e3).toFixed(1) + " K";
  } else {
    return number;
  }
}  
 function formatNumber(value) {
  let numValue = Number(value);
  if (isNaN(numValue)) {
    return `$${0}`;
  } else {
  if (value >= 1e6) {
    return `$${(value / 1e6).toFixed(2)}M`; 
  } else if (value >= 1e3) {
    return `$${(value / 1e3).toFixed(2)}K`; 
  } else {
    return `$${(value / 1).toFixed(2)}`; 
  }
}
}

export const payrollState = {
  namespaced: true,
  state: {
    payrollChartsData: null,
    payrollFilterOptionsDataTimeline: null,
    payrollFilterOptionsDataAnalysis: null,
    filterOptions: null,
    payrollAnalysisData: null,
    previousParams: null
  },
  mutations: {
    setPayrollChartsData(state, data) {
      state.payrollChartsData = data
    },
    setPayrollAnalysisData(state, data) {
      state.payrollAnalysisData = data
    },    
    setPayrollFilterOptionsDataTimeline(state, data) {
      state.payrollFilterOptionsDataTimeline = data
    },
    setPayrollFilterOptionsDataAnalysis(state, data) {
      state.payrollFilterOptionsDataAnalysis = data
    },
    setFilterOptions(state, data) {
      state.filterOptions = data
    },
    setPreviousParams(state, data){
      state.previousParams = data
    }   
  },
  methods: {
  },
  computed: {
    totalOverpaidEmpFormatted() {
      return NumberformatinKMB(Number(this.totalOverpaidEmpValue).toFixed(0));
    },
    totalUnderpaidEmpFormatted() {
      return NumberformatinKMB(Number(this.totalUnderpaidEmpValue).toFixed(0));
    },
  },
  actions: {
    haveParamsChanged(previousParams, newParams) {
      return JSON.stringify(previousParams) !== JSON.stringify(newParams);
    }, 

    async getPayrollFilterData(context, param) {
      try {
        const {
          entity_id, source, employee_id, employee_type,
          employee_status, employee_load, payment_date, financial_year, payment_range,
        } = param;
        const url = `/payrollanalysis`;
        const params = {
          entity_id: entity_id, 
          employee_id: Array.isArray(employee_id) ? employee_id.join(',') : employee_id ?? null,
          employee_type: Array.isArray(employee_type) ? employee_type.join(',') : employee_type ?? null,
          employee_status: Array.isArray(employee_status) ? employee_status.join(',') : employee_status ?? null, 
          employee_load: Array.isArray(employee_load) ? employee_load.join(',') : employee_load ?? null, 
          payment_date: Array.isArray(payment_date) ? payment_date.join(',') : payment_date ?? null,
          financial_year: Array.isArray(financial_year) ? financial_year.join(',') : financial_year ?? null,
          payment_range_min: payment_range?.[0] ?? null, // part of the array in payment_range with min and max values
          payment_range_max: payment_range?.[1] ?? null
        };

        let payrollChartData = []
        if (context.state.payrollAnalysisData && JSON.stringify(context.state.previousParams) === JSON.stringify(params)) {
          payrollChartData = context.state.payrollAnalysisData
        }
        else {
          payrollChartData = await httpClient.get(url, { params });
          context.commit('setPayrollAnalysisData', payrollChartData)
          context.commit('setPreviousParams', params)
        }
        if (payrollChartData["data"]["message"])
          throw new Error(payrollChartData["data"]["message"]);

        let underpaymentdata = underpaymentKeyReasons
        let overpaymentdata = overpaymentKeyReasons
        let val = [];
        if (source === 'timeline')
          val = context.state.payrollFilterOptionsDataTimeline
        else if (source === 'analysis')
          val = context.state.payrollFilterOptionsDataAnalysis
        let filterOptions = {};
        if (val === null) {
          filterOptions["employeeIdOptions"] = payrollChartData["data"]["uniqueEmpId"];
          filterOptions["employeeTypeOptions"] = payrollChartData["data"]["uniqueEmpType"];
          filterOptions["employeeLoadOptions"] = payrollChartData["data"]["uniqueEmpLoad"];
          filterOptions["employeeStatusOptions"] = payrollChartData["data"]["uniqueEmpStatus"];
          filterOptions["paymentOptions"] = payrollChartData["data"]["uniquePayment"];
          filterOptions["financialOptions"] = payrollChartData["data"]["uniqueFinancial"];

          if (source === 'timeline')
            context.commit('setPayrollFilterOptionsDataTimeline', filterOptions)
          else if (source === 'analysis')
            context.commit('setPayrollFilterOptionsDataAnalysis', filterOptions)
        }
        let totalOverpaidEmp = payrollChartData["data"]["totalOverpaidEmp"];
        let totalUnderpaidEmp = payrollChartData["data"]["totalUnderpaidEmp"];
        const underpaymentyear = payrollChartData["data"]["underpaymentyear"];
        const underpaymentyearvalues = payrollChartData["data"]["underpaymentyearvalues"];
        const overpaymentyear = payrollChartData["data"]["overpaymentyear"];
        const overpaymentyearvalues = payrollChartData["data"]["overpaymentyearvalues"];
        const overpaymentvalues = payrollChartData["data"]["overpaymentvalues"];
        const underpaymentvalues = payrollChartData["data"]["underpaymentvalues"];
        const undermonthArray = payrollChartData["data"]["undermonthArray"];
        const overmonthArray = payrollChartData["data"]["overmonthArray"];
        const totalUnderpaymentPerAward = payrollChartData["data"]["totalUnderpaymentPerAward"] || {};
        const totalOverpaymentPerAward = payrollChartData["data"]["totalOverpaymentPerAward"] || {};
        let charts = []
        charts["positon"] = [{
          "id": 1,
          "value": "Employee",
          "focused": false
        },
        ]
        charts["trend"] = [
          {
            "id": 1,
            "focused": true,
            "bar_overpayment_data": [
              {
                x: overpaymentyear,
                y: overpaymentyearvalues,
                "type": 'bar',
                "marker": {
                  "color": 'darkorange'
                }
              }
            ],
          },
          {
            "id": 2,
            "focused": true,
            "bar_underpayment_data": [
              {
                "x": underpaymentyear,
                "y": underpaymentyearvalues,
                "type": 'bar',
                "marker": {
                  "color": 'cornflowerblue'
                }
              }
            ]
          },
          {
            "id": 3,
            "focused": true,
            "pie_overpayment_data": [
              {
                "labels": Object.keys(totalOverpaymentPerAward),
                "values": Object.values(totalOverpaymentPerAward),
                "type": "pie",
                "textinfo": "label+percent",
                "marker": {
                  "colors": [
                    "FF9F72",
                    "FF5100",
                    "3F4A7F",
                    "E8EAF4",
                    "3C956E",
                    "679FC2",
                    "555A73",
                    "2D303E"
                  ]
                }
              }
            ]
          },
          {
            "id": 4,
            "focused": true,
            "pie_underpayment_data": [
              {
                "labels": Object.keys(totalUnderpaymentPerAward),
                "values": Object.values(totalUnderpaymentPerAward),
                "type": "pie",
                "textinfo": "label+percent",
                "marker": {
                  "colors": [
                    "FF9F72",
                    "FF5100",
                    "3F4A7F",
                    "E8EAF4",
                    "3C956E",
                    "679FC2",
                    "555A73",
                    "2D303E"
                  ],
                  "line": {
                    "color": 'rgba(0,0,0,0)',
                    "width": 0
                  }
                },
              }
            ]
          },
          {
            "id": 5,
            "focused": true,
            "bar_overpayment_timeline_data": [
              {
                x: overmonthArray,
                y: overpaymentvalues,
                type: 'bar',
                marker: {
                  color: 'darkorange',
                }
              }
            ]
          },
          {
            "id": 6,
            "focused": true,
            "bar_underpayment_timeline_data": [
              {
                x: undermonthArray,
                y: underpaymentvalues,
                type: 'bar',
                marker: {
                  color: 'cornflowerblue',
                }
              }
            ]
          }
        ]
        charts["data"] = {
          "key_reasons_underpayment": [...new Set(underpaymentdata.map(item => item.payment_type))],
          "key_reasons_overpayment": [...new Set(overpaymentdata.map(item => item.payment_type))],
          "total_underpayment": formatNumber(Number(payrollChartData["data"]["total_underpayment"]).toFixed(2)),
          "total_overpayment": formatNumber(Number(payrollChartData["data"]["total_overpayment"]).toFixed(2)),
          "totalOverpaidEmp": NumberformatinKMB(Number(totalOverpaidEmp).toFixed(0)),
          "totalUnderpaidEmp": NumberformatinKMB(Number(totalUnderpaidEmp).toFixed(0))
        };
        context.commit('setPayrollChartsData', charts)
      }
      catch (error) {
        console.error('Error in getPayrollFilterData:', error);
        throw error;
      }
    }
  } 
}
