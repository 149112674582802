import { render, staticRenderFns } from "./payrolldashboard.vue?vue&type=template&id=606bdd93&scoped=true"
import script from "./payrolldashboard.vue?vue&type=script&lang=js"
export * from "./payrolldashboard.vue?vue&type=script&lang=js"
import style0 from "./payrolldashboard.vue?vue&type=style&index=0&id=606bdd93&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "606bdd93",
  null
  
)

export default component.exports