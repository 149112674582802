<template>
  <iframe 
    id="grafanaDashboard" 
    width="100%" 
    height="1200" 
    frameborder="0"                    
    :src="graphanaUrl" 
    v-if="graphanaUrl">         
  </iframe>     
</template>
<script>

import { mapState, mapActions } from "vuex";
import appInsights from '../../../appInsights';
import { config } from "@/app/config";

export default {
    name: "adminanalytics",   
    data () {
      return {
        loading: false,
        pageStartTime: null,
        graphanaUrl: config.api.graphanaUrl || '', 
      }
    },
    async mounted(){     
      this.pageStartTime = Date.now(); 
      appInsights.trackPageView({
        name: 'Analytics Page',
        properties: {
          currentRoute: 'adminanalytics'
        }      
    });
  },
  destroyed() {
    const timeSpent = Date.now() - this.pageStartTime; 
    appInsights.trackPageView({
      name:  'Analytics Page',
      properties: {
        duration: timeSpent,
        currentRoute: 'adminanalytics',
        entity: this.selectedEntity.entity_name,
        user: (this.user?.firstName || '') + ' ' + (this.user?.lastName||'')
      }
    });
  },
    watch: {
      selectedEntity(newValue, oldValue) {
      this.handleSelectedEntityChange(newValue, oldValue);
    }
    },
    computed:{
      ...mapState({
        user: state => state.user,
        selectedEntity: state => state.user.selectedEntity, 
      }), 
    },
  
    methods: {
      ...mapActions(
        {         
        }
      ),      
      showTimeline () {
        this.$router.push({ name: 'payroll/timeline' })
          // eslint-disable-next-line handle-callback-err
          .catch(err => {})
      },      
      async handleSelectedEntityChange(newValue, oldValue) {
        await this.load_payroll_filters_data();
      },  
      showAnalysis () {
        this.$router.push({ name: 'payroll/analysis' })
          // eslint-disable-next-line handle-callback-err
          .catch(err => {})
      },
      showJobs () {
        this.$router.push({ name: 'joblist' })
          // eslint-disable-next-line handle-callback-err
          .catch(err => {})
      },        
      showDirective () {
        this.$router.push({ name: 'payroll' })
          // eslint-disable-next-line handle-callback-err
          .catch(err => {})
      },
       showUpload () {
        this.$router.push({ name: 'payroll' })
          // eslint-disable-next-line handle-callback-err
          .catch(err => {})
      },        
      showSupport () {  
        this.$router.push({ name: 'payroll' })
          // eslint-disable-next-line handle-callback-err
          .catch(err => {})     
      },  
    }      
}
</script>

<style lang="scss" scoped>
.customdivCharts {
  align-content: center;
  margin-left: 50px;
}
</style>
