<template>
  <div>
    <div class="selectionContainer">
      <div class="option">
        <label>Employee ID</label>
        <multiselect :value="selected_values.employeeIdDefaultOption"
                     tag-placeholder="Add this as new tag"
                     placeholder="Type keyword here "
                     label="label"
                     track-by="value"
                     :options="employee_id_options"
                     :multiple="true"
                     :taggable="true"
                     :showLabels="false"
                     @tag="addTag"
                     @input="commit_employee_id" />
      </div>
   
      <div class="option">
        <label>Employee type</label>
        <multiselect :value="selected_values.employeeTypeDefaultOption"
                     tag-placeholder="Add this as new tag"
                     placeholder="Type keyword here"
                     label="label"
                     track-by="value"
                     :options="employee_type_options"
                     :multiple="true"
                     :taggable="true"
                     :showLabels="false"
                     @tag="addTag"
                     @input="commit_employee_type" />
      </div>
    
      <div class="option">
        <label>Employee load</label>
        <multiselect :value="selected_values.employeeLoadDefaultOption"
                     tag-placeholder="Add this as new tag"
                     placeholder="Type keyword here"
                     label="label"
                     track-by="value"
                     :options="employee_load_options"
                     :multiple="true"
                     :taggable="true"
                     :showLabels="false"
                     @tag="addTag"
                     @input="commit_employee_load" />
      </div>
     
      <div class="option">
        <label>Employee status</label>
        <multiselect :value="selected_values.employeeStatusDefaultOption"
                     tag-placeholder="Add this as new tag"
                     placeholder="Type keyword here"
                     label="label"
                     track-by="value"
                     :options="employee_status_options"
                     :multiple="true"
                     :taggable="true"
                     :showLabels="false"
                     @tag="addTag"
                     @input="commit_employee_status" />
      </div>
   
      <div class="option">
        <label>Payment date</label>
        <multiselect :value="selected_values.paymentDefaultOption"
                     tag-placeholder="Add this as new tag"
                     placeholder="Type keyword here"
                     label="label"
                     track-by="value"
                     :options="payment_date_options"
                     :multiple="true"
                     :taggable="true"
                     :showLabels="false"
                     @tag="addTag"
                     @input="commit_payment_date" />
      </div>
     
      <div class="option">
        <label>Financial year</label>
        <multiselect :value="selected_values.financialDefaultOption"
                     tag-placeholder="Add this as new tag"
                     placeholder="Type keyword here"
                     label="label"
                     track-by="value"
                     :options="financial_year_options"
                     :multiple="true"
                     :taggable="true"
                     :showLabels="false"
                     @tag="addTag"
                     @input="commit_financial_year" />
      </div>
    </div>
    <div class="selectionContainer">
      <p class="display-7">Min/Max ($):</p>
      <!-- <div class="option">
        
        Selected Values : {{ selectedPaymentRangeValue }}
        <va-slider
          range
          v-model="selectedPaymentRangeValue"
          :min="minValue"
          :max="maxValue"
          value-visible
        />
      </div> -->
      <div class="option">
        <div class="customRangeSlider">
          <div class="container input-container">
            <input type="number" v-model="selectedPaymentRangeValue[0]">
            <span> - </span>
            <input type="number" v-model="selectedPaymentRangeValue[1]">
            <va-button @click="submitRangeFilter" class="button">Filter</va-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import { AvailableColorThemes, ColorThemes } from '../../../../layouts/portal/services/themes-config'
export default {
  name:'PayrollFilter',
  components: {
    Multiselect,
  },
  props: {
    filter_options: Object,
    filtered_values: Object
  },

  data() {
    const employee_id_options_tmp= this.filter_options?.employeeIdOptions.map(item => {
      return {
          value: item,
          label: item
      };
    });
    const employee_type_options_tmp = this.filter_options?.employeeTypeOptions.map(item => {
      return {
          value: item,
          label: item
      };
    });

    const employee_load_options_tmp = this.filter_options?.employeeLoadOptions.map(item => {
      return {
          value: item,
          label: item
      };
    });

    const employee_status_options_tmp = this.filter_options?.employeeStatusOptions.map(item => {
      return {
          value: item,
          label: item
      };
    });
    const payment_date_options_tmp = this.filter_options?.paymentOptions.map(item => {
      return {
          value: item,
          label: item
      };
    });
    const financial_year_options_tmp = this.filter_options?.financialOptions.map(item => {
      return {
          value: item,
          label: item
      };
    });
  
    return {
      employee_id_options:  employee_id_options_tmp,
      employee_type_options:employee_type_options_tmp,
      employee_load_options: employee_load_options_tmp,
      employee_status_options: employee_status_options_tmp,
      payment_date_options: payment_date_options_tmp,
      financial_year_options: financial_year_options_tmp, 
      
      rangeValue: 100,
      minValue: 0,
      maxValue: 50000,
      step: 1,
      selectedPaymentRangeValue: [0, 50000],
      selected_values: {
        employeeIdDefaultOption: [],
        employeeTypeDefaultOption: [],
        employeeStatusDefaultOption: [],
        employeeLoadDefaultOption: [],
        paymentDefaultOption: [],
        financialDefaultOption: [],
        paymentRangeDefaultOption: [],

      }
    };
  },
  watch: {
    filter_options: function (new_value) {  
      // console.log('new_value', new_value);
      if (new_value) {
        this.employee_id_options = new_value["employeeIdOptions"];
        this.employee_type_options = new_value["employeeTypeOptions"];
        this.employee_status_options = new_value["employeeLoadOptions"];
        this.employee_load_options = new_value["employeeStatusOptions"];
        this.payment_date_options = new_value["paymentOptions"];
        this.financial_year_options = new_value["financialOptions"];
        this.selectedPaymentRangeValue = this.selectedPaymentRangeValue
      } else {
        this.employee_id_options = [];
        this.employee_type_options = [];
        this.employee_status_options = [];
        this.employee_load_options = [];
        this.payment_date_options = [];
        this.financial_year_options = [];
        this.selectedPaymentRangeValue = [10, 50]
      }
    },
    filtered_values: function(new_value) {
      if (new_value) {
        this.selected_values.employeeIdDefaultOption =
          new_value["employeeIdDefaultOption"];

        this.selected_values.employeeTypeDefaultOption =
          new_value["employeeTypeDefaultOption"];

        this.selected_values.employeeStatusDefaultOption =
          new_value["employeeStatusDefaultOption"];

        this.selected_values.employeeLoadDefaultOption =
          new_value["employeeLoadDefaultOption"];

        this.selected_values.paymentDefaultOption =
          new_value["paymentDefaultOption"];

        this.selected_values.financialDefaultOption =
          new_value["financialDefaultOption"];       
      }
    }
  },
  methods: {
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      };
    },

    commit_any_filter(new_value, field) {      
      var new_data = JSON.parse(JSON.stringify(this.selected_values));
      new_data[field] = new_value;     
      this.$emit("update_filter_value", new_data);
    },

    commit_employee_id(new_value) {
      this.commit_any_filter(new_value, "employeeIdDefaultOption");
    },
    commit_employee_type(new_value) {
      this.commit_any_filter(new_value, "employeeTypeDefaultOption");
    },
    commit_employee_load(new_value) {
      this.commit_any_filter(new_value, "employeeLoadDefaultOption");
    },
    commit_employee_status(new_value) {
      this.commit_any_filter(new_value, "employeeStatusDefaultOption");
    },
    commit_payment_date(new_value) {
      this.commit_any_filter(new_value, "paymentDefaultOption");
    },
    commit_financial_year(new_value) {
      this.commit_any_filter(new_value, "financialDefaultOption");
    },    
    // for range implementation
    commit_range_filter(min, max){   
      this.commit_any_filter([min, max], "paymentRangeDefaultOption");
    },
    submitRangeFilter(){
      // console.log('selectedPaymentRangeValue', this.selectedPaymentRangeValue);
      this.commit_range_filter(this.selectedPaymentRangeValue[0], this.selectedPaymentRangeValue[1]);
    },
    updatePayroll(){
      this.$emit("refresh_payroll_data");
    },

    
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.selectionContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
}

.filter {
  border: none;
  border-radius: 0.375rem;
  padding-right: 20px;
  background: white;
}

.input-container {
  padding: 0;
  justify-content: space-between;
  align-items: center;

  & input {
    height: 43px;
    min-height: 40px;
    width: 40%;
    max-width: 100%;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    color: #34495e;
    text-align: center;

    &.distanceInput {
      width: 100%;
    }
  }
}

.second-input {
  margin-left: 10px;
  margin-right: -1rem;
}

.option {
  flex: 1;
  margin-right: 10px;
}

.buttonContainer {
  padding: 10px;
  display: flex;
  justify-content: center;
}

.customRangeSlider {
  position: relative;
  top: 35px;
  left: 25px;
}

.option .multiselect__tag {
  background: #0eacbd;

  & .multiselect__tag-icon::after {
    color: white;
  }
}

</style>