<template>
  <div> 
    <plotly v-if="plotlyData.data?.length > 0" 
      :data="plotlyData.data"
      :layout="plotlyData.layout"
      :options="plotlyData.options"
      :autoResize="plotlyData.autoResize" 
    />
  </div>    
</template>

<script>

export default {
  props:{
    cardTitle: null, 
    rawData: null, 
    xColumn: null, 
    yColumns: null,
    focusedColumn: null, 
    xAxisTitle: null, 
    yAxisTitle: null, 
  },
  data() {
    return {
        plotlyData: {
      data: this.rawData,
      layout: {
        autosize: true,
        title: {
        text: this.cardTitle,
            font: {
              size: 24,
              weight: 'bold'   
            },
        },
        plot_bgcolor: "#FFFFFF",
        margin: {'t': 40,'l':40, 'r':40, 'b':40}, 
        showlegend: true,
        legend: {
          orientation: 'v',
          x: 5,
          y:0.5,
          bgcolor: 'rgba(0,0,0,0)'
        },
        annotations: [
          {
            text: 'Chart Title',
            showarrow: false,
            x: 5, 
            y: 0.5, 
            xref: 'paper',
            yref: 'paper',
            xanchor: 'center',
            yanchor: 'bottom',
            font: {
              size: 16,
              color: '#5f6e78'
            }
          }
        ]
      }, 
      options: { displaylogo: false }, 
      autoResize: true, 
    }
  
     /* plotlyData: {
        data:[],
        layout:{
          autosize: true, 
          plot_bgcolor: "#FFFFFF",
          margin: {'t': 40,'l':40, 'r':40, 'b':40}, 
          showlegend: true,
          legend: {
            orientation: 'h',
            x: 0,
            y: 1.1,
            bgcolor: 'rgba(0,0,0,0)'
          }
        }, 
        options: { displaylogo: false }, 
        autoResize: true, 
      }*/
    };
   
  },
  watch: {
    rawData: function(new_value, old_value) {
      if (new_value) {
        if (this.yColumns) {
          var arrayLength = new_value.length;
          var binary_colors = getBinaryColors();
          var focused_color = binary_colors['FOCUSED'];
          var not_focused_color = binary_colors['NOT_FOCUSED'];

          var labels = [];
          var values = [];

          for (var i = 0; i < arrayLength; i++) {
            var x_value = new_value[i][this.xColumn];
            var y_value = new_value[i][this.yColumns[0]];

            labels.push(x_value);
            values.push(y_value);

            var trace = {
              labels: labels,
              values: values,
              type: 'pie',
              textinfo: 'label+percent',
              marker: {
                colors: y_value ? [focused_color] : [not_focused_color]
              }
            };

            this.plotlyData.data = [trace];
          }

          this.plotlyData.layout.xaxis.title = this.xAxisTitle; 
          this.plotlyData.layout.yaxis.title = this.yAxisTitle; 
        }
      }
    },
  },
};
</script>

<style>
.card {
  border-radius: 3px;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.21);
  background-color: transparent;
}
</style>
