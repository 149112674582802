<template>
  <div style="margin: 1rem;">
    <va-card  class="card" :style="{ background: color ,height: height, width: width, borderRadius: '20px', border: border}">
      <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100%;">
        <p :style="{ fontSize: fontsize, color: fontcolor }">{{ title }}</p>
        <p :style="{ fontSize: 'xxx-large', color: fontcolor, marginTop: '1rem' }">{{ value }}</p>
      </div>
    </va-card>   
  </div>    
</template>

<script>

export default {
  props:{
    title: null, 
    value: null, 
    color: null,
    height: null ,
    fontsize: null ,
    width: null, 
    fontcolor: null,
    border :null
  },
  data() {
    return {      
    };
  },
  
};
</script>

<style>
.card {
  border-radius: 5px;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.21);
  background-color: transparent;
  height: 150px;
  width: 400px;
}
</style>
