
<template>
   <va-card class="container" style="overflow-x: auto; overflow-y: visible;">
    <div class="header">
      <p class="display-5" style="color: #5f6e78;">{{ title }}</p>
    </div>
    <div class="body">
      <p class="message">Please confirm the deletion of Rule</p>
      </div>
      <div class="footer">
        <va-button class="btn" @click="cancel">CANCEL</va-button>
        <va-button class="btn" @click="save">OK</va-button>
      </div>
   
  </va-card> 
</template>

<script>
import { httpClient } from '@/app/shared/services/http-client'
import { mapState, mapActions } from "vuex";

export default {
  name: "DeleteRuleModal",
  props: {
    title: String,
    propsCategory: "",
    propsComment: "",
    propsNumber: "",
    propsJobId: ""
  },
  data() {
    return {
      rule_description: "",
      rule_memo: "",
      rule_number: 99,      
    };
  },
  mounted() {   
    this.rule_description = this.propsCategory
    this.rule_memo = this.propsComment
    this.rule_number = this.propsNumber
    this.job_id = this.propsJobId
  },
  methods: {
    cancel() {
      this.$emit("close");
    },
    async save() {           
      const body = {
        'number': this.rule_number,        
        'job_id': this.job_id,
        'mode': 'delete'
      };
      
      await httpClient.put(`/editRule?rule_number=${this.rule_number}`, body, {headers: {'Content-Type': 'multipart/form-data'}})
      .then((res) => {
        console.log(res.data)
      })
      
      this.$emit("deletedata", {
        'number': this.rule_number,
        'description' : this.rule_description,
        'memo' : this.rule_memo
      })
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
.container {
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
  height: 300px;
  overflow-y: hidden;
}

.header {
  text-align: center;
  margin-bottom: 16px;
  margin-top: 2rem;
}

.display-5 {
  color: #34495e;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
}

.body {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.message {
  margin-bottom: 16px;
  margin-top: 2rem;
  font-size: 1.5rem;
}

.footer {
  display: flex;
  justify-content: center;
  padding: 16px;
  border-top: 1px solid #e0e0e0;
  margin-top: 1.5rem;
}

.btn {
  margin: 0 8px;
}

.alignright {
  float: right;
}

.alignleft {
  float: left;
}
</style>