import { httpClient } from '@/app/shared/services/http-client'
import { loadingService } from '@/app/shared/services/loading-service'

export const reportState = {
  namespaced: true,
  state: {
    availableReports:{},
    reportInfo:{
        accessToken: '',
        embedUrl: '',
        tokenExpiry: ''
    }
  },
  mutations: {
    setReportInfo(state, data){
        state.reportInfo = data
    },
    setAvailableReports(state, data) {
        state.availableReports = data
    }
  },
  actions: {

    async getReportList(context, entityId){
      const url = `/pbi/reportList/${entityId}`
      //loadingService.showLoading(true);

      return await httpClient.get(url)
        .then(res => {
            const data = res.data;
            let result = {'success': true, 'errorMessage': ''};

            if(!data.message){
                context.commit('setAvailableReports', data.reports)
            }
            else{
                result.success = false;
                result.errorMessage = 'Error retrieve report list';
            }
            return result;
        })
        .catch(err => {       
            console.log(err);    
            throw err; // reject
        })
        .finally(() =>{
            //loadingService.showLoading(false);
        })
    },

    async getReportEmbedInfo(context, params) { 
      //call report API
      const url = `/pbi/reportEmbedInfo/${params.codename}`
      loadingService.showLoading(true);
      try {
        const res = await httpClient.get(url);       
        const data = res.data;
        let result = { success: true, errorMessage: '' };

        if (!data.message) {
            context.commit('setReportInfo', data);
        } else {
            result.success = false;
            result.errorMessage = 'Error retrieving report information';
        }        
        return result;
    } catch (err) {      
        // Handle the error as needed
        return { success: false, errorMessage: err.message || 'An error occurred' };
    } finally {       
        loadingService.showLoading(false); // Hide loading in finally block
    }
    },

    async exportReportAndEmail(context, params){
      //call report API
      const url = `/pbi/reportExportAndEmail/${params.codename}`
      loadingService.showLoading(true);
      
      return await httpClient.get(url)
        .then(res => {
            const data = res.data;
            let result = {'success': true, 'errorMessage': ''};

            if(!data.message){
                //context.commit('setReportInfo', data)
            }
            else{
                result.success = false;
                result.errorMessage = 'Error trigger export-to-file logic';
            }
            return result;
        })
        .catch(err => {       
            console.log(err);    
            throw err; // reject
        })
        .finally(() =>{
            loadingService.showLoading(false);
        })      
    }

  },

}
