import { render, staticRenderFns } from "./FileUploader.vue?vue&type=template&id=40fb90bd&scoped=true"
var script = {}
import style0 from "./FileUploader.vue?vue&type=style&index=0&id=40fb90bd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40fb90bd",
  null
  
)

export default component.exports