<template>
 <div class="schoolList">
    <modals-container />
    <va-card>
 
    <div class="row align--center">
      <div v-if="!showCompare"  class="flex xs12 ">
        <va-input
          :value="term"
          :placeholder="'Search by name'"
          @input="search"
          removable
        >
          <va-icon name="fa fa-search" slot="prepend" />
        </va-input>
      </div>
      <div v-if="showCompare"  class="flex xs12 ">
       <label class="jobheader"> EBA Comparison in {{ this.selectedjobs?.length>0?this.selectedjobs[0].job_name:'Select Job to compare'}} and {{ this.selectedjobs?.length>1?this.selectedjobs[1].job_name :'Select Job to compare'}}</label>
      </div>
      <div  v-if="showCompare" class="flex center">
        <va-button @click="compareSelectedRows">Compare</va-button>
      </div>
      <div  v-if="!showCompare" class="flex xs3">
        <va-select 
          v-model="perPage"
          :label="'Per page'"
          :options="perPageOptions"
          noClear
        />
      </div>
      
    </div>
  
    <div v-if="showContainer" class="container"> 
      <div id="container" class="container"></div>
    </div>
    <va-data-table v-if="!showContainer" 
      :fields="fields"
      :data="filteredData"
      :per-page="parseInt(perPage)"
    >

     <template slot="actions" slot-scope="props">
      <div class="button-container"> 
        <i v-if="props.rowData.selected === false" class="mdi mdi-checkbox-blank-outline icon-button" @click="selectedJobforCompare(props.rowData)"></i>
        <i v-if="props.rowData.selected === true"  class="mdi mdi-checkbox-blank icon-button" @click="selectedJobforCompare(props.rowData)"></i>
        <i class="mdi mdi-eye icon-button" @click="getSelectedJobDetails(props.rowData)"></i>
        <i class="mdi mdi-export icon-button" @click="exportToExcel(props.rowData)"></i> 
      </div>     
        </template>
    </va-data-table>
  </va-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { debounce } from 'lodash';
import { loadingService } from '@/app/shared/services/loading-service'
import formatDate from '../utilities';
import * as pbi from 'powerbi-client';
import { fetchNewToken } from './tokenService';
import appInsights from '../../../appInsights';

export default {
  name: "joblist",
  components:{ 
  },
  props:{ 
    
  },  
  data() {
    return {
      term: null,
      perPage: '10',
      showContainer: false,
      showCompare: false,
      selectedjobs:[],   
    //  codename:'505d29e3-25ab-43f1-bb6d-729f63d0941f',
      codename:'',
      perPageOptions: ['1', '6', '10', '20'],
        config: {
          type: 'report',
          tokenType:  pbi.models.TokenType.Embed,
          accessToken: '',
          embedUrl: '',
          permissions: pbi.models.Permissions.All,
          settings: {
              filterPaneEnabled: false,
              navContentPaneEnabled: true
          }
      },
      selected: null,
      powerbi: null,
      reportContainer: null,
      selectEntityId: 0,
      pageStartTime: null,
      currentStep: 3,
    }
  },
  computed:{
    ...mapState({
      jobs: state => state.admin.adminPayrollJobList ? state.admin.adminPayrollJobList.jobs : [],    
      selectedEntity: state => state.user.selectedEntity, 
      user: state => state.user,         
      adminJobDetailsExport :state => state.admin.adminJobDetailsExport ? state.admin.adminJobDetailsExport.rules : [],
      availableReports: state => state.report.availableReports ? state.report.availableReports : [],
      reportInfo: state => state.report.reportInfo
    }),
    
    
    fields () {
      return [
        {
          name: 'job_id',
          title: 'Job Id',
          sortField: 'job_id',
          width: '15%',
        },           
        {
          name: 'job_name',
          title: 'Job Name',
          sortField: 'job_name',
          width: '25%',        
        }, 
        {
          name: 'file_name',
          title: 'File Name',
          sortField: 'file_name',
          width: '25%',        
        }, 
        {
          name: 'job_status',
          title: 'Job Status',
          sortField: 'job_status',
          width: '15%',
        },
        {
          name: 'approvalstatus',
          title: 'Approved',
          sortField: 'approvalstatus',           
          width: '5%',
        },
        {
          name: 'estimated_time_minutes',
          title: 'Estimated Time to Finish',
          sortField: 'estimated_time_minutes',
          width: '15%',
        },       
        {
          name: 'created_date',
          title: 'Created Date',
          sortField: 'created_date',
          formatter: (value) => formatDate(value), 
          width: '15%',
        },                 
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        }
      ]
    },
    filteredData  () {
      if (!this.term || this.term.length < 1) {
        return this.jobs
      }

      return this.jobs.filter(item => {
        return item.step_name.toLowerCase().includes(this.term.toLowerCase())
      })
    },
  },

  watch: {
    reportInfo(newValue){
          if(newValue){
              config.accessToken = newValue.accessToken;
              config.embedUrl = newValue.embedUrl
              var report = this.powerbi.embed(this.reportContainer, this.config);
              if(report){
                report.on("loaded", function () {
                console.log("Report load successful")
              });
                report.off("loaded");

                report.off("rendered");

                report.on("error", function () {
                    report.off("error");
                });

              }
          }
      },
      availableReports(newValue){
        const categories = Object.keys(newValue);
        let reports = []
        categories.forEach(c => {
            let cate = {name: 'report', displayName: `${c} `, meta: {iconClass: 'entypo entypo-folder'}}
            cate.children = []
            newValue[c].forEach(r => {
                let report = {name: 'report', displayName: `${r.name}`, params: {codename: `${r.codename}`}, meta: {iconClass: 'vuestic-iconset vuestic-iconset-files'}}
                cate.children.push(report)
            })
            reports.push(cate)
        })
        this.reports_items = reports; 
      },  
  },
  methods:{
    ...mapActions({
      getAdminPayrollJobList: 'admin/getAdminPayrollJobList',    
      getJobDetailsExport:'admin/getJobDetailsExport',
      getJobDetails:'admin/getJobDetails',
      getReportEmbedInfo: 'report/getReportEmbedInfo',
      getReportList: "report/getReportList",
    }), 
    valueSelected(value){
        this.selected = value;
        const {workspace_id, report_id} = value;       
    }, 
    toggleContainer() {
    this.showContainer = !this.showContainer;
    this.$nextTick(() => {       
        this.reportContainer = document.getElementById('container');
        this.codename = this.availableReports?.filter(x=>x.name==="EBA Compare Simulation")[0]?.codename    
      });
  },
    async compareSelectedRows() {
      appInsights.trackEvent({ name: 'compareSelectedRows', properties: {       
        additionalInfo: 'Compare Selected Jobs',       
        currentRoute: window.location.pathname,
    } });  
      await this.toggleContainer()
      this.loadReport();
    },

    async loadReport() { 
      if(this.selectedEntity){
        await this.getReportList(this.selectedEntity.id)
      }
      
      this.codename = this.availableReports["Payroll Audit"]?.filter(x=>x.name==="EBA Compare Simulation")[0]?.codename 
      this.powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
      this.reportContainer = document.getElementById('container');
      if(this.codename){
        await this.getReportEmbedInfo({codename:this.codename})
      }

      this.config.accessToken = this.reportInfo.accessToken;
      this.config.embedUrl = this.reportInfo.embedUrl;
      if (this.reportContainer !== null && this.config.accessToken !== null && this.config.embedUrl !== null) {      
        const embedConfig = { ...this.config };        
        const report = this.powerbi.embed(this.reportContainer, embedConfig);  
            
        report.on('loaded', () => {
          console.log('Report load successful');          
        });

        report.on('error', (error) => {
          console.error('Report load error:', error);
        });
      }
    },

    selectedJobforCompare(selectedJob)
     {
       selectedJob.selected=!selectedJob.selected;
       this.selectedjobs.push(selectedJob)     
       if(this.jobs.filter(x=>x.selected===true).length===2)
        this.showCompare=true;
       else
        this.showCompare=false;
     },

    
     getSelectedJobDetails(selectedJob) {
      appInsights.trackEvent({ name: 'getSelectedJobDetails', properties: {       
        additionalInfo: 'Selected Job Details',       
        currentRoute: window.location.pathname,
      } });  
      
      const jobsData={
      "runtimeStatus":  selectedJob.job_status,
      "estimated_time_minutes" : selectedJob.estimated_time_minutes,
      "createdTime": selectedJob.created_date,
      "instanceId" : selectedJob.orchestration_id      
     }
     const filesData={
      "file_name":   selectedJob.file_name.split('.')[0],
      "file_ext" : selectedJob.file_name.split('.')[1],    
     }

      let currentStep = 3
      if(selectedJob.job_status.toLowerCase() === "success")
       currentStep = 7
      else
       currentStep = 3
    this.$router.push({ name: 'payroll',  query: { currentStep: currentStep, jobsData: jobsData, filesData:filesData} })  
  },
   
    search: debounce(function (term) {
      this.term = term
    }, 400),

    groupByRuleCategory(data) {
      const groupedData = {};
    
      data.forEach(row => {
        const { rule_category } = row;
        if (!groupedData[rule_category]) {
          groupedData[rule_category] = [];
        }
        groupedData[rule_category].push(row);
      });
    
      return groupedData;
    },
    exportToCSV(data,selectedJob) {
      let csvContent = "data:text/csv;charset=utf-8,";      
      let jobStatus = "Success";  
      appInsights.trackEvent({ name: 'exportToCSV', properties: {       
        additionalInfo: 'Export Job detials to CSV',       
        currentRoute: window.location.pathname,
    } });   
      data.forEach(row => {
        if (row.step_status !== "Success") {
          jobStatus = "In progress";
        }
      }); 

      csvContent += `Job ID: ${selectedJob.job_id},,Job Status: ${jobStatus}\r\n`;
    
      const groupedData = this.groupByRuleCategory(data);   
      Object.keys(groupedData).forEach(category => {
        csvContent += `Rule Category: ${category}\r\n`;       

        const header = Object.keys(groupedData[category][0]).filter(key => key !== 'job_id').join(',');
        csvContent += `${header}\r\n`;        

        groupedData[category].forEach(row => {
          const values = Object.values(row).filter((value, index) => index !== 0).map(value => `"${value}"`).join(',');
          csvContent += `${values}\r\n`;
        });
    
        csvContent += '\r\n';
      });    

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${this.selectedEntity.entity_name} - ${selectedJob.file_name.substring(0, selectedJob.file_name.lastIndexOf("."))}.csv`);
      document.body.appendChild(link);
 
      link.click();

      document.body.removeChild(link);
    },

  async exportToExcel(selectedJob) {
     this.getJobDetailsExport({ jobId: selectedJob.job_id });
     await this.exportToCSV(this.adminJobDetailsExport,selectedJob)    
    },
  convertToCSV(data) {   
      const csvRows = [];
      csvRows.push('"Title"');   
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(','));

      for (const row of data) {
        const values = headers.map(header => {
          const escaped = ('' + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(','));
      }

      return csvRows.join('\n');
    },
    async updateTokenAndEmbed() {
      try {        
        this.accessToken = await fetchNewToken();        
        this.embedConfig.accessToken = this.accessToken;      
        this.report = this.powerbi.embed(this.reportContainer, this.embedConfig);      
      } catch (error) {
        console.error('Error embedding Power BI report:', error);
      }
    }
  },
  mounted(){
    this.pageStartTime = Date.now(); // Start the timer
    if(this.selectedEntity.id){
      this.getAdminPayrollJobList({entityId: this.selectedEntity.id})  
      loadingService.showLoading(false)      
    } 
    else
     loadingService.showLoading(true) 
    
     appInsights.trackPageView({
      name: 'Job List Page',
      properties: {
        currentRoute: 'joblist'
      }
    });
  },
  destroyed() {
    const timeSpent = Date.now() - this.pageStartTime; 
    appInsights.trackPageView({
      name: 'Job List Page',
      properties: {
        duration: timeSpent,
        currentRoute: 'joblist',
        entity: this.selectedEntity.entity_name,
        user: (this.user?.firstName || '') + ' ' + (this.user?.lastName||'')
      }
    });
  },
  watch: {
    'selectedEntity.id': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getAdminPayrollJobList({entityId: this.selectedEntity.id})  
          loadingService.showLoading(false)   
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 0.75rem;
  padding-top: 1rem;
  width: 100%;
  height: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  & .reportTable {
    width: 100%;
  }
}

.containerreport {
  padding: 0.75rem;
  padding-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  & .reportTable {
    width: 100%;
  }
}

.checkbox-column {
  width: 50px;
  text-align: center;
}

.report {
  width: 100%;
  height: 100%;
}

.row {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.flex {
  display: flex;
  margin-right: 10px;
}

.jobheader {
  font-size: larger;
  font-weight: bold;
}

.button-container {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
}

.icon-button {
  cursor: pointer;
  font-size: 30px;
  margin-right: 8px;
  color: rgb(212, 61, 39);
}

.center {
  justify-content: left;
}

.alignright {
  float: right;
}

</style>