<template>
    <div class="report">
        <!-- <div class="option">
        <label>Availble Report:</label>
        <multiselect
          :value="selected"
          :options="availableReports"
          :multiple="false"
          :allow-empty="false"
          :showLabels="false"
          trackBy="id"
          label="name"
          :searchable="false"
          @input="valueSelected"
          placeholder="Select report">
        </multiselect>
      </div> -->
      <div class="flex xs12 " align="right"> 
      <div v-if ="codename !== 'activity'" class="container">
        <va-button  @click="clickDownloadAsPDF()">Email as PDF</va-button>
      </div>
        </div> 
        <div class="container">
        <div v-if ="codename !== 'activity'" id="container" class="container">
        </div>
        <div v-else>         
          <adminanalytics/>      
      </div>
        </div>
    </div>
</template>

<script>
import * as pbi from 'powerbi-client';
import Multiselect from "vue-multiselect";
import { mapState, mapActions } from "vuex";
import { httpClient } from '@/app/shared/services/http-client'
import appInsights from '../../../appInsights';
import adminanalytics from './adminanalytics.vue';

export default {
    name: "report",
    components: {
        Multiselect,
        adminanalytics
    },
    props:{
        codename:String,
        name: String
    },
  data() {
    return {
        config: {
          type: 'report',
          tokenType:  pbi.models.TokenType.Embed,
          accessToken: '',
          embedUrl: '',
          permissions: pbi.models.Permissions.All,
          settings: {
              filterPaneEnabled: false,
              navContentPaneEnabled: true
          }
      },
      pageStartTime: null,
      selected: null,
      powerbi: null,
      reportContainer: null,
      selectEntityId: 0,
      reportName: '',
    }
  },
 
  computed: {
      ...mapState({
      availableReports: state => state.report.availableReports ? state.report.availableReports : [],
      reportInfo: state => state.report.reportInfo,
      user: state => state.user,
      selectedEntity: state => state.user.selectedEntity,  
    }),
  },
  watch:{
      reportInfo(newValue){
          if(newValue){
              this.reportContainer = document.getElementById('container');
              this.config.accessToken = newValue.accessToken;
              this.config.embedUrl = newValue.embedUrl

              var report = this.powerbi.embed(this.reportContainer, this.config);              
              if(report){
                report.on("loaded", function () {
                console.log("Report load successful")
              });
                report.off("loaded");
                report.off("rendered");

                report.on("error", function () {
                    report.off("error");
                });

              }
            
              const timeSpent = Date.now() - this.pageStartTime; 
              appInsights.trackPageView({
              name: 'Report - ' +  this.name ,
              properties: {
                duration: timeSpent,
                currentRoute: 'report',
                entity: this.selectedEntity.entity_name,
                user: (this.user?.firstName || '') + ' ' + (this.user?.lastName||'')
              }
            });
          }
      },
      codename(newValue, oldValue){
          if(newValue && newValue !== oldValue){
            if(this.codename && this.codename !== 'activity'){
               this.getReportEmbedInfo({codename:this.codename})
            }            
          }        
      }
  },
  methods: {
      ...mapActions({
            getReportEmbedInfo: "report/getReportEmbedInfo",
            exportReportAndEmail: "report/exportReportAndEmail",
        }),
    valueSelected(value){
        this.selected = value;
        const {workspace_id, report_id} = value;
        //this.getReportEmbedInfo({workspaceId:workspace_id, reportId:report_id})
    }, 
    clickDownloadAsPDF(){

      var r = window.confirm('Do you want the report be exported as a pdf document and send to your email box?');
      if(r==true){
        console.log('proceed with the action of export to file and email')
        this.exportReportAndEmail({codename:this.codename})
      }
      else{
        console.log('cancel the action of export to file and email')
      } 
      
    }
  },
  mounted (){ 
      this.powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
      this.reportContainer = document.getElementById('container');
      if(this.codename && this.codename !== 'activity'){
          this.getReportEmbedInfo({codename:this.codename})
      }
      // if(this.codename && this.codename !== 'activity' ) 
      //   this.getReportEmbedInfo({codename:this.codename})
      // else if(this.codename && this.codename !== 'activity' && oldValue !== 'activity')
      //   this.getReportEmbedInfo({codename:this.oldValue})
      // else if(this.codename && this.codename === 'activity' && newValue !== 'activity')
      //   this.getReportEmbedInfo({codename:this.newValue}) 
      // else if(this.codename && this.codename === 'activity' && oldValue === 'activity')
      //   this.getReportEmbedInfo({codename:this.newValue})
      // else if(this.codename && this.codename !== 'activity' && newValue === 'activity')
      //   this.getReportEmbedInfo({codename:this.oldValue})

      this.pageStartTime = Date.now(); 
      appInsights.trackPageView({
      name: 'Report - ' +  this.name ,
      properties: {
        currentRoute: 'report',
        entity: this.selectedEntity.entity_name,
        user: (this.user?.firstName || '') + ' ' + (this.user?.lastName||'')
      }
    });      
  },
 
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.report {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

.container {
  width: 100%;
  height: 100%;
}

.option {
  margin-bottom: 1rem;

  & .multiselect {
    margin-top: 10px;
  }
}

</style>

