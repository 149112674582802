import Payroll from './views/payroll'
import Job from './views/job'
import JobList from './views/joblist'
import Timeline from './views/dashboard/timeline'
import Analysis from './views/dashboard/analysis'
import PayrollDashboard from './views/dashboard/payrolldashboard'
const payrollRoutes = [
    {
        path: 'payrolldashboard',
        name: 'payrolldashboard',
        component: PayrollDashboard,       
    },
    {
        path: 'payroll',
        name: 'payroll',
        component: Payroll,
        meta: {
        breadcrumb: [
            { name: '' }
        ]
        }
    },
    {
        path: 'job',
        name: 'job',
        component: Job,
        meta: {
        breadcrumb: [
            { name: '' }
        ]
        }
    },
    {
        path: 'joblist',
        name: 'joblist',
        component: JobList,       
    },
    {
        path: 'payroll/timeline',
        name: 'payroll/timeline',
        component: Timeline,       
    },
    {
        path: 'payroll/analysis',
        name: 'payroll/analysis',
        component: Analysis,       
    }   
]

export default payrollRoutes
