<template>
  <va-card class="container" style="overflow-x: auto; overflow-y: visible;">
    <p class="display-5" style="color: #5f6e78;">{{title}}</p>

    <div class="body">
      <va-input
        v-model="rule_description"
        label="Rule Description"
        rows="2"
        outlined
        type="textarea"
      />
      <va-input
        v-model="rule_memo"
        label="Rule Comment"
        rows="2"
        outlined
        type="textarea"
      ></va-input>
      <va-button class="btn alignleft" @click="cancel">CANCEL</va-button>
      <va-button
        class="btn alignright"
        @click="save"
      >SAVE</va-button>
    </div>
  </va-card>
</template>
<script>

import { httpClient } from '@/app/shared/services/http-client'
import { mapState, mapActions } from "vuex";

export default {
  name: "CreateRuleModal",
  props: {
    title: String,
    propsCategory: "",
    propsDescription: "",
    propsComment: "",
    propsInstanceId:""
  },
  data() {
    return {
      rule_description: "",
      rule_memo: ""
    };
  },
  mounted() {
    this.rule_description = this.propsDescription
    this.rule_memo = this.propsComment
    this.rule_category = this.propsCategory
    this.instance_id = this.propsInstanceId
    this.rule_number=0
  },
  methods: {
    cancel() {
      this.$emit("close");
    },
    async save() {

      const body = {       
        'rule' : this.rule_description,
        'comment' : this.rule_memo,
        'instance_id': this.instance_id,
        'mode': 'add',
        'rule_category': this.rule_category,
        'employment_type': ''
      };
      
      await httpClient.post(`/editRule?rule_number=${this.rule_number}`, body, {headers: {'Content-Type': 'multipart/form-data'}})
      .then((res) => {
        console.log(res.data)
      })

      this.$emit("savedata", {
        'description' : this.rule_description,
        'memo' : this.rule_memo
      })
      this.$emit("close");
    }
  }
};
</script>

<style scoped>
.container {
  box-shadow: none !important;
}

.body {
  margin-top: 1rem;
}

.alignright {
  float: right;
}

.alignleft {
  float: left;
}
</style>