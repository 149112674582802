import SchoolList from './views/school-list'

const schoolsRoutes = [
  {
    path: 'school-list',
    name: 'school-list',
    component: SchoolList,
  },
]

export default schoolsRoutes
