export const defaultMenuItems = {
    routes: [
        {
          name: 'dashboard',
          displayName: 'Dashboard',
          meta: {
            iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
          },
        },      
    ]
}

export const educationMenuItems = {
  routes: [
    {
      name: 'benchmark_report',
      displayName: 'Benchmark Report',
      meta: {
        iconClass: 'entypo entypo-chart-bar',
      },
    },
    {
      name: 'benchmark',
      displayName: 'Benchmark Tool',
      meta: {
        iconClass: 'entypo entypo-chart-line',
      },
    },    
    {
      name: 'map',
      displayName: 'Map',
      meta: {
        iconClass: 'entypo entypo-map',
      },
    }
  ]
}

export const payrollMenuItems = {
  routes: [    
    {
      name: 'payrolldashboard',
      displayName: 'Dashboard',
      meta: {
        iconClass: 'vuestic-iconset vuestic-iconset-files',
      },
    },
    {
      name: 'job',
      displayName: 'Create Job',
      meta: {
        iconClass: 'vuestic-iconset vuestic-iconset-files',
      },
    },
    {
      name: 'joblist',
      displayName: 'Job List',
      meta: {
        iconClass: 'vuestic-iconset vuestic-iconset-settings',
      },
    },
    {     
      name: 'analytics',
      displayName: 'Analytics',
      meta: {
        iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
      },
      children: [
        {
          name: 'payroll/analysis',
          displayName: 'Analysis',
          meta: {
            iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
          },
        },      
        // {
        //   name: 'payroll/timeline',
        //   displayName: 'Timeline',
        //   meta: {
        //     iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
        //   },
        // }, 
      ]
    },      
  ]
}



