<template>
  <div class="benchmark">
    <va-card>   
      <div class="row row-equal">      
          <div v-if="payrollFilterOptionsData !== null" class="flex xs12">
               <PayrollFilter :filter_options="payrollFilterOptionsData"
                                :filtered_values="filtered_values"
                                @update_filter_value="get_new_filter_value"
                                @refresh_payroll_data="refresh_payroll_data"></PayrollFilter>
        </div>
      </div>

      <div class="row row-equal" :style="{alignItems:'center',justifyContent:'space-evenly'}">      
        <div class="flex xs12 md6 xl4" > 
          <TileChart :key="barChartKeytimeline" title='Total underpayment' :value="total_underpayment" color='cornflowerblue' fontsize='x-large'  fontcolor="white" border ="none"/>
        </div>
        <div class="flex xs12 md6 xl4 ">
          <TileChart :key="barChartKeytimeline" title='Total overpayment' :value="total_overpayment" color='darkorange'  fontsize='x-large'  fontcolor="white" border ="none"/>
        </div>
      </div>     
    
      <div class="flex  customCharts"> 
        <BarChart :key="barChartKeytimeline" title='Employee underpayment over time' :rawData="bar_underpayment_timeline_data" :layout= "bar_overpayment_data_layout" color='cornflowerblue' />
      </div>
      <div class="flex customCharts">
        <BarChart :key="barChartKeytimeline" title='Employee overpayment over time' :rawData="bar_overpayment_timeline_data" :layout= "bar_overpayment_data_layout" color='darkorange'/>
      </div>  
    </va-card>
  </div>    
</template>
<script>

import PayrollFilter from '../components/payroll-filter'
import { mapState, mapActions } from "vuex";
import { loadingService } from '@/app/shared/services/loading-service'
import BarChart from '../components/Charts/BarChart';
import TileChart from '../components/Charts/TileChart';
import appInsights from '../../../../appInsights';

export default {
    name: "timeline",
    components: {      
        PayrollFilter,
        BarChart,
        TileChart,
    },
    data () {      
      const bar_underpayment_data_layout = {     
        yaxis: {         
          tickvals: [0,200,400,500, 600, 700,800,900,1000],          
          hoverformat: '$f',
          tickmode: 'array',
          autotick: false       
        }        
      };

      const bar_overpayment_data_layout = {     
        yaxis: {         
          tickvals: [0, 200, 400, 600, 800, 1000],        
          hoverformat: '$f',
          tickmode: 'array',
          autotick: false       
        }  
      };

      return {
        loading: false,
        barChartKeytimeline: 0,
        filtered_values: null,
        new_filter_value: null,
        bar_underpayment_data_layout :bar_underpayment_data_layout,    
        bar_overpayment_data_layout: bar_overpayment_data_layout,  
        pageStartTime: null       
      }
    },
    mounted(){
    this.pageStartTime = Date.now(); 
    appInsights.trackPageView({
      name: 'Timeline Page',
      properties: {
        currentRoute: 'timeline'
      }
    });
  },
  destroyed() {
    const timeSpent = Date.now() - this.pageStartTime; 
    appInsights.trackPageView({
      name: 'Timeline Page',
      properties: {
        duration: timeSpent,
        currentRoute: 'timeline',
        entity: this.selectedEntity.entity_name,
        user: (this.user?.firstName || '') + ' ' + (this.user?.lastName||'')
      }
    });
  },
    
    computed:{
      ...mapState({
        selectedEntity: state => state.user.selectedEntity,  
        user: state => state.user,          
        payrollFilterOptionsData: state=>state.payroll?.payrollFilterOptionsDataTimeline,           
        payrollChartsData: state=>state.payroll?.payrollChartsData,  
        bar_underpayment_timeline_data: state=>state.payroll?.payrollChartsData?.trend[5]?.bar_underpayment_timeline_data,
        bar_overpayment_timeline_data: state=>state.payroll?.payrollChartsData?.trend[4]?.bar_overpayment_timeline_data, 
        total_underpayment :  state=>state.payroll?.payrollChartsData?.data?.total_underpayment,
        total_overpayment :  state=>state.payroll?.payrollChartsData?.data?.total_overpayment,
      }), 
      focused_entity_id: function(){
        if(this.selectedEntity !== null){
            return this.selectedEntity.id
        }
        else{
            return null
        }            
      },
    },

    async created(){ 
        const promises = [
          this.load_filtered_data()
        ];
        Promise.all(promises).then(responses => {   
          console.log(responses); 
        })
        .catch(err => {  
              console.log(err);             
          })
        .finally(() => {  this.loading = false; loadingService.showLoading(false) }) 
    },
  
    watch: {
      bar_underpayment_timeline_data: function(newData, oldData) {
        this.barChartKeytimeline++;
      },
      focused_entity_id: function(){
        if (this.focused_entity_id) {                
          const promises = [                
            this.load_filtered_data()
          ];
          
          Promise.all(promises)
          .catch(err => {       
          console.log(err);    
          throw err; 
          })
          .finally(()=>{ this.loading = false; loadingService.showLoading(false)})        
        }
      },
    },
  
    methods: {
      ...mapActions(
        {
         loadPayrollFilterData: "payroll/getPayrollFilterData",        
        }
      ),

      get_value_out_of_list(list_of_dict, value_field){
        var tmp = []
        for(var i=0; i<list_of_dict.length; i++){
            tmp.push(list_of_dict[i][value_field])
        }
        return tmp
      },        

      async load_filtered_data(){
        let param={};
        if( this.focused_entity_id ) {
          if (this.filtered_values) {
            let employee_id = this.get_value_out_of_list( this.filtered_values?.employeeIdDefaultOption, 'value' )
            let employee_type = this.get_value_out_of_list( this.filtered_values?.employeeTypeDefaultOption, 'value' )
            let employee_status = this.get_value_out_of_list( this.filtered_values?.employeeStatusDefaultOption, 'value' )           
            let employee_load = this.get_value_out_of_list( this.filtered_values?.employeeLoadDefaultOption, 'value' )
            let payment_date = this.get_value_out_of_list( this.filtered_values?.paymentDefaultOption, 'value' )
            let financial_year = this.get_value_out_of_list( this.filtered_values?.financialDefaultOption, 'value' )          
            let payment_range = Object.values(this.filtered_values?.paymentRangeDefaultOption) || []
            
            let param = {
              "entity_id": this.selectedEntity.id,   
              "source": "timeline",
              "employee_id": employee_id, 
              "employee_type": employee_type,
              "employee_status"  : employee_status, 
              "employee_load" : employee_load, 
              "payment_date": payment_date, 
              "financial_year": financial_year,
              "payment_range": payment_range                                    
            }
            loadingService.showLoading(true)  
            const promises = [                
              this.loadPayrollFilterData( param )
            ];
            
            Promise.all(promises)
            .catch(err => {       
            console.log(err);    
            throw err; 
            })
            .finally(()=>{
              loadingService.showLoading(false)
              })       
          }          
          else {
             param = {
              "entity_id": this.selectedEntity.id,   
              "source": "timeline",
             }
             try {
              loadingService.showLoading(true)
              await this.loadPayrollFilterData( param ).finally(()=>{loadingService.showLoading(false)})
            } catch (error) {
              console.error('Error loading payroll data:', error);            
            } finally {
              loadingService.showLoading(false);
            }
          }          
        }   
        else
        {
          loadingService.showLoading(false)  
          this.loading = false;
        }
      },

      get_new_filter_value(new_filter_value){
        this.filtered_values = new_filter_value
        this.new_filter_value = new_filter_value
        this.load_filtered_data()        
      },

      refresh_payroll_data(){  
        this.load_filtered_data()
        .finally(()=>{loadingService.showLoading(false)})
      },
     }
}
</script>

<style lang="scss" scoped>
.customdivCharts {
  align-content: center;
  margin-left: 50px;
}
</style>
