<template>
  <div id="main-container">
    <modals-container @savedata="handleSaveRule" />
    <Header />   
   
      <div class="row" style="justify-content: center; margin-bottom: 2rem;">     
        <FileUploader  @change="handleFileChange" />
      </div>
      <div class="row" style="justify-content: center; margin-bottom: 1rem;" >       
        <p style="font-size: 20px; font-weight: 600;">
          File uploaded - <span v-if="uploadFile">{{ uploadFile?.name }}</span>               
          <span v-else style="color: red;">NO FILE UPLOADED</span>          
        </p>
      </div>
      <div class="row" style="justify-content: center; margin-bottom: 1rem;" >  
       <span v-if="showInvalidFileError" style="color: red; font-size: 20px;">Invalid file type</span>  
      </div>
      <div class="row" style="justify-content: center; margin-bottom: 2rem;" >  
        <p style="font-size: 20px; font-weight: 400;">Valid file types are: jpeg, png, bmp, tiff, jpg, html, docx, doc, pdf, txt</p>
      </div>
      <div class="row" style="justify-content: center; margin-bottom: 2rem;" >       
        <p style="font-size: 20px; font-weight: 600;">
          Enter Job Name <span style="color: red;">*</span>  - <span v-if="uploadFile"> <input class="flex xs12 form-input" v-bind:class="{ hasError: nameError !== '' }" v-model="job_name" placeholder="Enter job name here">  </span>
          <span v-else style="color: red;">Job Name is Required</span>
        </p>
      </div>
      <div class="row" style="justify-content: center; margin-bottom: 1rem;" >   
        <p style="font-size: 20px; font-weight: 600;" v-if="nameError !== ''" class="error-label">{{nameError}}</p>
      </div>
      <div  class="row" style="justify-content: center; margin-bottom: 2rem;">
        <ActionButton label="Submit Documents" @button-clicked="handleButtonClick" :disabled="isButtonDisabled"  />
      </div>
    </div> 
 
</template>

<script>
import PayrollDataTable from "./components/PayrollDataTable.vue";
import CreateRuleModal from "./components/CreateRuleModal.vue";
import EditRuleModal from "./components/EditRuleModal.vue";
import Header from "./components/Header.vue";
import FileUploader from "./components/FileUploader.vue";
import ActionButton from "./components/ActionButton.vue";
import PayrollTabs from "./components/PayrollTabs.vue";
import ActionButtons from "./components/ActionButtons.vue";
import VModal from 'vue-js-modal/dist/ssr.index';
import { httpClient } from '@/app/shared/services/http-client'
import { loadingService } from '@/app/shared/services/loading-service'
import 'vue-step-progress/dist/main.css';
import { mapState, mapActions } from "vuex";
import appInsights from '../../../appInsights';

export default {
  name: "job",
  components: {
    PayrollDataTable,
    Header,
    FileUploader,
    ActionButton,
    PayrollTabs,
    ActionButtons,
    CreateRuleModal,
    EditRuleModal,  
  },
 
  data() {
    return {    
      uploadFile: null,
      uploadFileContent: null,
      selectedDataSetIndex: 0,
      isChecked: false,
      isFileUploaded: false,
      isModalVisible: false,      
      filesData: [],
      job_name: null ,
      nameError: null,   
      valid: true,
      showInvalidFileError: false,
      isExists: false
    }
  },
  computed: {
    ...mapState({    
      selectedEntity: state => state.user.selectedEntity,  
      user: state => state.user,              
    }),
    isButtonDisabled() {   
    return this.uploadFile === null || this.job_name === null ;
  }
   },
   mounted(){
    this.pageStartTime = Date.now(); 
    appInsights.trackPageView({
      name: 'Job Page',
      properties: {
        currentRoute: 'job'
      }
    });
  },
  destroyed() {
    const timeSpent = Date.now() - this.pageStartTime; 
    appInsights.trackPageView({
      name: 'Job Page',
      properties: {
        duration: timeSpent,
        currentRoute: 'job',
        entity: this.selectedEntity.entity_name,
        user: (this.user?.firstName || '') + ' ' + (this.user?.lastName||'')
      }
    });
  },

  methods: {
    // ...mapActions({     
    //   CheckJobExists:'payroll/CheckJobExists',
    // }), 
    openCreateRuleModal() {
      this.$modal.show(CreateRuleModal,
        {
          title: 'Create New Rule',
          propsCategory: "",
          propsComment: ""
        },
        {
          isAutoHeight: true,
          resizable: false,
          adaptive: true,
          draggable: true,
          root: this.$root
        },
      )
    },
    handleSaveRule(childData) {
      const currentItems = this.getItemsForSelectedTab
      currentItems.push({
        number: currentItems.length + 1,
        Rule: childData.description,
        comment: childData.memo
      })
    },    
    openFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      appInsights.trackEvent({ name: 'Upload Documents', properties: {       
        additionalInfo: 'Upload document to create job instance',       
        currentRoute: window.location.pathname,
    } });  
      const selectedFile = event.target.files[0]
      const validTypes = [
          'image/jpeg', 'image/png', 'image/bmp', 'image/tiff',
          'text/html', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/msword', 'application/pdf', 'text/plain'
        ];
        if (validTypes.includes(selectedFile.type)) {
          this.uploadFile = selectedFile
          this.displaySelectedImage(selectedFile)
        }
        else
        {
          this.showInvalidFileError= true;       
        }
    },
    displaySelectedImage(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.selectedImage = e.target.result;
        };
        reader.readAsDataURL(file)
      }
    },
    async CheckJobExists(param){
      const {
        job_name
      } = param;   
      const url = `/checkjobexists`; 
      const result = await httpClient.get(url , {job_name: job_name});    
      return result[jobs]
    },
    async isJobExists(job_name)
    {
    //  const response= await this.checkJobExists({ job_name: job_name });
      const url = `/checkjobexists`; 
      const params={job_name: job_name}
      const response = await httpClient.get(url , {params});  
      if( response["data"]["jobs"].length > 0)
        return true
      else
        return false      
    },
    async handleButtonClick() {
      appInsights.trackEvent({ name: 'Submit Documents', properties: {       
        additionalInfo: 'Submit document to create new job instance',       
        currentRoute: window.location.pathname,
    } });  
      if (this.uploadFile !== null) {
        const self = this
        let isValidJobName = true;
        loadingService.showLoading(false);

        var name_regex = new RegExp("^[a-zA-Z0-9_-]+$");      

        if(this.job_name.trim() === ""){
            this.nameError = 'Required' ;
            loadingService.showLoading(false);  
            isValidJobName= false        
        }
        else if( ! name_regex.test(this.job_name) ){
            this.nameError = 'Only alphabet and space are allowed' ;  
            loadingService.showLoading(false); 
            isValidJobName= false                  
        }
        else if( await this.isJobExists(this.job_name) ){
            this.nameError = 'Job name already exists' ;  
            loadingService.showLoading(false); 
            isValidJobName= false                  
        }
      
        if(isValidJobName){       
          this.nameError = ""       

          var formData = new FormData(); 
          this.currentStep = 1;
          formData.append('file', this.uploadFile, this.uploadFile.name)
          formData.append('entity_id', this.selectedEntity.id)
          formData.append('job_name', this.job_name)
          
          await httpClient.post('/upload', formData)
            .then(response => {
              const jsonData = Object.values(response)[0]            

              this.jobsData = response['data']['jobsData'];
              this.filesData = response['data']['filesData'];
              this.entityId = response['data']['entityId'];
              this.queryData = response['data']['queryData'];

              if(this.jobsData && this.jobsData['runtimeStatus'].toLowerCase() == 'running') {             
                this.currentStep = this.currentStep + 3;  
              }
              else  if(this.jobsData && this.jobsData['runtimeStatus'].toLowerCase() == 'pending'){
                this.currentStep = this.currentStep + 2;               
              }
              this.$router.push({ name: 'payroll',  query: { currentStep: this.currentStep, jobsData: this.jobsData, filesData:this.filesData} })  
            })
            .catch(err => {
              console.log(err);             
              const jsonData = []

              this.itemsShift = jsonData.filter((rule) => ['Shift'].includes(rule.Category)).map((item, index) => ({
                ...item,
                number: index + 1,
              }));
              this.itemsOvertime = jsonData.filter((rule) => ['Overtime'].includes(rule.Category)).map((item, index) => ({
                ...item,
                number: index + 1,
              }));
              this.itemsRDO = jsonData.filter((rule) => ['RDO'].includes(rule.Category)).map((item, index) => ({
                ...item,
                number: index + 1,
              }));
              this.itemsAllowance = jsonData.filter((rule) => ['Allowance'].includes(rule.Category)).map((item, index) => ({
                ...item,
                number: index + 1,
              }));
              this.itemsLeave = jsonData.filter((rule) => ['Leave'].includes(rule.Category)).map((item, index) => ({
                ...item,
                number: index + 1,
              }));
              this.itemsSuper = jsonData.filter((rule) => ['Super'].includes(rule.Category)).map((item, index) => ({
                ...item,
                number: index + 1,
              }));
              this.itemsMiscellaneous = jsonData.filter
                ((rule) => !['Shift', 'Overtime', 'RDO', 'Allowance', 'Leave', 'Super'].includes(rule.Category)).map((item, index) => ({
                  ...item,
                  number: index + 1,
                }))
              this.isFileUploaded = true
            })
            .finally(() => {
              // console.log('Show Loading false');
              loadingService.showLoading(false);
            })
        }
      }
    },
    handleReviewButtonClick() {
      console.log('clicked')
    },
    handleFinalButtonClick() {    
      this.isModalVisible = true;     
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-file-input-label {
  cursor: pointer;
  display: inline-block;
}

.button-image {
  max-width: 100%;
  height: auto;
}

.va-card-style {
  overflow-x: auto;
  background-color: #eeeeee;
  color: white;
}

.va-card-style-internal {
  overflow-x: auto;
  color: black;
}

#main-container {
  background-size: auto;
  color: black;
}

.modal-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust this if you want the container to take less vertical space */
}

.file-info {
  padding-right: 1rem; /* Adjust as needed */
}

.action-button {
  margin-left: 1rem; /* Adjust as needed */
}

.error-label {
  font-size: 0.75rem;
  color: #d43d27;
  font-weight: bold;
  margin-left: 0.25rem;
}
</style>