<template>
  <div>
    <va-button large  :disabled="disabled" @click="handleButtonClick">{{ label }}  </va-button>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    disabled: {
      type: Boolean,
      default: false
    }
  },
   methods: {
    handleButtonClick() {     
      if (!this.disabled) {
      this.$emit('button-clicked');
      }
    },
    handleFinalButtonClick() {    
      this.$emit('button-clicked');
    },
  },
};
</script>

<style scoped>
.action-button {
  margin-top: 5rem;
}
</style>
