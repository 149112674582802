<template>
  <div class="benchmark">
    <va-card>    
      <div class="row row-equal"> 
        <div  v-if="payrollFilterOptionsData !== null" class="flex xs12">        
          <PayrollFilter :filter_options="payrollFilterOptionsData"
                                :filtered_values="filtered_values"
                                @update_filter_value="get_new_filter_value"
                                @refresh_payroll_data="refresh_payroll_data"></PayrollFilter>

        </div>
      </div>

      <div class="row row-equal" :style="{alignItems:'center',justifyContent:'space-evenly'}">      
        <div class="flex xs12 md6 xl4" > 
         <BarChart :key="barChartKey"  title='Employee underpayment over time'  :rawData="bar_underpayment_data" :layout= "bar_underpayment_data_layout" color='cornflowerblue'/>
        </div>
        <div class="flex xs12 md6 xl4 ">
          <PieChart :key="barChartKey" cardTitle='Employee underpayment by Award' :rawData="pie_underpayment_data"/>
        </div>
       
        <div class="flex xs12 md6 xl4 ">       
          <div class="flex xs12 md6 xl4" > 
            <TileChart :key="barChartKeyanalysis" title='Total Underpayments' :value="total_underpayment" color='red' fontsize='x-large'  fontcolor="white" border ="none" width="270px"/>
          </div>
          <div class="flex xs12 md6 xl4 ">
            <TileChart :key="barChartKeyanalysis" title='Underpaid Employees' :value="totalUnderpaidEmp" color='red'  fontsize='x-large'  fontcolor="white" border ="none" width="270px"/>
          </div> 
        </div>      
      </div>
      
      <div class="row row-equal" :style="{alignItems:'center',justifyContent:'space-evenly',borderTop: '2px solid darkgrey'}">       
        <div class="flex xs12 md6 xl4 "> 
          <BarChart :key="barChartKey" title='Employee overpayment over time'  :rawData="bar_overpayment_data"  :layout= "bar_overpayment_data_layout" color='darkorange'/>
        </div>
        <div class="flex xs12 md6 xl4 ">
           <PieChart :key="barChartKey" cardTitle='Employee overpayment by Award' :rawData="pie_overpayment_data"/>
        </div>
        <div class="flex xs12 md6 xl4 ">              
          <div class="flex xs12 md6 xl4" > 
            <TileChart :key="barChartKeyanalysis" title='Total Overpayments' :value="total_overpayment" color='red' fontsize='x-large'  fontcolor="white" border ="none"  width="270px"/>
          </div>
          <div class="flex xs12 md6 xl4 ">
            <TileChart :key="barChartKeyanalysis" title='Overpaid Employees' :value="totalOverpaidEmp" color='red'  fontsize='x-large'  fontcolor="white" border ="none"  width="270px"/>
          </div>
        </div>    
      </div>
    </va-card>
  </div>    
</template>

<script>

import PayrollFilter from '../components/payroll-filter'
import { mapState, mapActions } from "vuex";
import { loadingService } from '@/app/shared/services/loading-service'
import BarChart from '../components/Charts/BarChart';
import TileChart from '../components/Charts/TileChart';
import PieChart from '../components/Charts/PieChart';
import appInsights from '../../../../appInsights';

export default {
    name: "analysis",
    components: {      
        PayrollFilter,
        BarChart,
        TileChart,
        PieChart,
    },
    data () {
      const bar_underpayment_data_layout = {     
        yaxis: { 
          tickvals: [0,1000,2000,3000, 4000,5000,6000,7000,8000,9000,10000], 
          hoverformat: '$f',
          tickmode: 'array',
          autotick: false          
        }  
      };

      const bar_overpayment_data_layout = {     
        yaxis: {         
          tickvals: [0, 400, 800, 1200, 1600, 2000],         
          hoverformat: '$f',
          tickmode: 'array',
          autotick: false     ,
          text: ["8739009.02", "21575.67"],  
        },
      };
    
      const underpayment_total_key =[{"title": "Total Underpayments"},{"value": "250"} ]
      const underpayment_employee_key =[{"title": "Underpaid Employees"},{"value": "100"} ]
      const overpayment_total_key =[{"title": "Total Overpayments"},{"value": "150"} ]
      const overpayment_employee_key =[{"title": "Overpaid Employees"},{"value": "300"} ]

      return {
        loading: false,
        barChartKey: 0,
        barChartKeyanalysis: 0,
        config: { value_field: 'value', label_field: 'label' },
        filtered_values: null,
        new_filter_value: null,                               
        bar_underpayment_data_layout :bar_underpayment_data_layout,    
        bar_overpayment_data_layout: bar_overpayment_data_layout,   
        underpayment_total_key: underpayment_total_key,
        underpayment_employee_key: underpayment_employee_key,
        overpayment_total_key: overpayment_total_key,
        overpayment_employee_key: overpayment_employee_key, 
        pageStartTime: null
      }
    },

    computed:{
      ...mapState({
          selectedEntity: state => state.user.selectedEntity,
          user: state => state.user,
          payrollFilterOptionsData: state=>state.payroll?.payrollFilterOptionsDataAnalysis,
          payrollChartsData: state=>state.payroll?.payrollChartsData,
          bar_underpayment_data: state=>state.payroll?.payrollChartsData?.trend[1]?.bar_underpayment_data,
          bar_overpayment_data: state=>state.payroll?.payrollChartsData?.trend[0]?.bar_overpayment_data,
          pie_underpayment_data: state=>state.payroll?.payrollChartsData?.trend[3]?.pie_underpayment_data,
          pie_overpayment_data: state=>state.payroll?.payrollChartsData?.trend[2]?.pie_overpayment_data,
          key_reasons_underpayment :  state=>state.payroll.payrollChartsData?.data?.key_reasons_underpayment,
          key_reasons_overpayment :  state=>state.payroll.payrollChartsData?.data?.key_reasons_overpayment,
          total_underpayment :  state=>state.payroll?.payrollChartsData?.data?.total_underpayment,
          total_overpayment :  state=>state.payroll?.payrollChartsData?.data?.total_overpayment,
          totalOverpaidEmp : state=>state.payroll?.payrollChartsData?.data?.totalOverpaidEmp,
          totalUnderpaidEmp : state=>state.payroll?.payrollChartsData?.data?.totalUnderpaidEmp,
      }), 
      focused_entity_id: function(){      
        if(this.selectedEntity !== null){
            return this.selectedEntity.id
        }
        else {
          return null
        }            
      },
    },

    async created(){       
      const promises = [
        this.load_filtered_data(),
      ];
      Promise.all(promises).then(responses => {   
        console.log(responses);  
      })
      .catch(err => {  
            console.log(err);
      })
      .finally(() => {  
        this.loading = false; loadingService.showLoading(false) 
      }) 
    }, 
  
    watch: {     
      bar_underpayment_data: function(newData, oldData) {
        this.barChartKey++;
        this.barChartKeyanalysis++
      },
      focused_entity_id: function(){
        if (this.focused_entity_id) {                
          const promises = [                
            this.load_filtered_data()
          ];
          
          Promise.all(promises)
          .catch(err => {       
          console.log(err);    
          throw err; 
          })
          .finally(()=>{
             this.loading = false; loadingService.showLoading(false)
            })        
        }
      },
    },
    mounted(){
    this.pageStartTime = Date.now(); 
    appInsights.trackPageView({
      name: 'Analysis Page',
      properties: {
        currentRoute: 'analysis',
        entity: this.selectedEntity.entity_name,
      }
    });
  },
  destroyed() {  
    const timeSpent = Date.now() - this.pageStartTime; 
    appInsights.trackPageView({
      name: 'Analysis Page',
      properties: {
        duration: timeSpent,
        currentRoute: 'analysis',
        entity: this.selectedEntity.entity_name,
        user: (this.user?.firstName || '') + ' ' + (this.user?.lastName||'')
      }
    });
  },
    methods: {
      ...mapActions(
        {
        loadPayrollFilterData: "payroll/getPayrollFilterData",
        }
      ),

      async load_filtered_data() {       
        let param = {};
        if (this.focused_entity_id) {
          if (this.filtered_values) {
            let employee_id = this.get_value_out_of_list(this.filtered_values?.employeeIdDefaultOption, 'value')
            let employee_type = this.get_value_out_of_list(this.filtered_values?.employeeTypeDefaultOption, 'value')
            let employee_status = this.get_value_out_of_list(this.filtered_values?.employeeStatusDefaultOption, 'value')
            let employee_load = this.get_value_out_of_list(this.filtered_values?.employeeLoadDefaultOption, 'value')
            let payment_date = this.get_value_out_of_list(this.filtered_values?.paymentDefaultOption, 'value')
            let financial_year = this.get_value_out_of_list(this.filtered_values?.financialDefaultOption, 'value')
            let payment_range = Object.values(this.filtered_values?.paymentRangeDefaultOption) || []
            //this.get_value_out_of_list(this.filtered_values?.paymentRangeDefaultOption, 'value')

            let param = {
              "entity_id": this.selectedEntity.id,
              "source": "analysis",
              "employee_id": employee_id,
              "employee_type": employee_type,
              "employee_status": employee_status,
              "employee_load": employee_load,
              "payment_date": payment_date,
              "financial_year": financial_year,
              "payment_range": payment_range
            }          
            loadingService.showLoading(true)          
            const promises = [
              this.loadPayrollFilterData(param)
            ];

            Promise.all(promises)
              .catch(err => {
                console.log(err);
                throw err;
              })
              .finally(() => {
                this.loading = false; loadingService.showLoading(false)
              })             
          }
          else {         
            param = {
              "entity_id": this.selectedEntity.id,
              "source": "analysis",
            }
            try {
              loadingService.showLoading(true)
              await this.loadPayrollFilterData(param).finally(() => { this.loading = false;loadingService.showLoading(false) })
            } catch (error) {   
              console.error('Error loading payroll data:', error);          
            } finally {
              loadingService.showLoading(false);
              this.loading = false;       
            }
          }
        }
        else {
          loadingService.showLoading(false)
          this.loading = false;
        }
      },

    get_value_out_of_list(list_of_dict, value_field){
      var tmp = []
      for(var i=0; i<list_of_dict.length; i++){
          tmp.push(list_of_dict[i][value_field])
      }
      return tmp
    },

    get_new_filter_value(new_filter_value){
      this.filtered_values = new_filter_value
      this.new_filter_value = new_filter_value
      this.load_filtered_data()      
    },
    // for range implementation
    refresh_payroll_data() {
        this.load_filtered_data()       
    },

  }
}
</script>

<style lang="scss" scoped>
.customdivCharts {
  align-content: center;
  margin-left: 50px;
}
</style>
